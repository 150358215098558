import React,{useState,useEffect} from "react";

import { toast } from 'react-toastify';
import {
  useLocation,
  useNavigate,
  matchRoutes
} from "react-router-dom";
import {PLAYLIST_POST_TYPE,RADIO_POST_TYPE,LIVE_TAG_ID,DEBUG} from "../../Constants";
import { useUser } from "../../user/User.Context";
import {redirectToPost,getRouteUsername,getRoutePlaylistType,isRouteFavoritePosts,getToastErrorMessage} from "../../utils/Utils";
import PlaylistEditModal from "../../playlist/edit/PlaylistEditModal";
import PostQuery from "../../components/PostQuery";


const MusicPosts = (props) => {

  let location = useLocation();
  let navigate = useNavigate();
  const [query,setQuery] = useState();

  const username = getRouteUsername(location);
  const playlistType = getRoutePlaylistType(location);

  const isFriends = (username === 'friends');
  const isCommunity = (username === 'community');
  const routeFavoritedPosts = isRouteFavoritePosts(location);

  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;
  const isMe = (username === userContext.user.profile?.username);
  const canCreatePost = userContext.user.profile?.capabilities.create_posts;
  const myFollowedUserIds = (userContext.user.profile?.users_followed || []).map(function(user){
    return user.ID;
  })


  //show add new post button on certain pages only
  //TOUFIX URGENT
  const hasNewPostButton = ( canCreatePost && [
    `/music/${myUsername}/playlists`,
    `/user/${myUsername}/playlists`,

  ].includes(location.pathname) );

  const [isNewPlaylist,setIsNewPlaylist] = useState(false);

  const handleNewPlaylistModal = () => {
    setIsNewPlaylist(true);
  }

  const handleAddPlaylist = async(playlist) => {

    //TOUFIX POST TYPE HARDCODED - should be set in modal
    playlist.setMetaValue('post_type',PLAYLIST_POST_TYPE);

    return userContext.addPost(playlist)
    .then(function(newPlaylist){
      const url = newPlaylist.getPostUrl();
      navigate(url);
    })
    .catch(error => {
      toast.error(getToastErrorMessage(error,'Error while creating post'));
    })
  }

  //build playlists query
  useEffect(() => {

    let query = {
      posts_per_page:-1
    }

    switch(playlistType){
      case "static":
        query['post_type'] = PLAYLIST_POST_TYPE;
      break;
      case 'cloud':
        query['post_type'] = RADIO_POST_TYPE;
        query['tag__not_in'] = LIVE_TAG_ID;
      break;
      case 'live':
        query['post_type'] = RADIO_POST_TYPE;
        query['tag__in'] = LIVE_TAG_ID;
      break;
    }

    switch(username){
      case 'friends':
        query = {
          ...query,
          author__in: myFollowedUserIds
        }
      break;
      case 'community':
        //all
      break;
      default:
        query = {
          ...query,
          author_name:username
        }
      break;
    }

    if (routeFavoritedPosts){

      query = {
        ...query,
        favorite_posts:true,
      }

      if (isFriends){
        delete query.author__in;
        query = {
          ...query,
          favoriter__in: myFollowedUserIds
        }
      }else if(username && !isCommunity){
        delete query.author_name;
        query = {
          ...query,
          favoriter_name:username
        }
      }

    }

    setQuery(query);

  }, [location]);



  return (
    <>
      {
        isNewPlaylist &&
        <PlaylistEditModal
        newItem={true}
        open={true}
        onClose={()=>{setIsNewPlaylist(false)}}
        onSubmit={handleAddPlaylist}
        />
      }
      {
        <PostQuery
        query={query}
        onNewItem={hasNewPostButton ? handleNewPlaylistModal : undefined}
        />
      }

    </>
  )
}

export default MusicPosts;
