import React, { useState,useEffect } from "react";
import {
  Routes,
  Route,
  useParams,
  useLocation,
  matchRoutes
} from "react-router-dom";
import { Loader,Grid } from 'semantic-ui-react';
import { useUser } from "../../user/User.Context";
import {DEBUG} from "../../Constants";
import DatabaseAPI from "../../databaseAPI/api";
import UserProfile from "./profile";
import UserTracks from "./UserTracks";
import MusicPosts from "../music/posts";

import ApiErrorMessage from "../../components/ApiErrorMessage";
import UserSettingsPage from "./settings/index";

export const UserPage = (props) => {

  let location = useLocation();

  const getLocationParams = () => {
    const routesMatch = matchRoutes([
      {path:'/user/:username/*'}
    ], location);

    const match = routesMatch ? routesMatch[0] : undefined;
    return match?.params;
  }

  const locationParams = getLocationParams();


  const userContext = useUser();
  const isMe = (locationParams?.username === userContext.user.profile?.username);

  const [profile,setProfile] = useState();

  const [error,setError] = useState();
  const [favTrackCount,setFavTrackCount] = useState();
  const [favPostCount,setFavPostCount] = useState();
  const [postCount,setPostCount] = useState();


  const [isNewPlaylist,setIsNewPlaylist] = useState(false);

  const userImage = profile?.image ? 'url('+profile.image+')' : '';

  const handleFavTracksInit = jspfPost => {
    setFavTrackCount(jspfPost.track.length);
  }

  const handlePostsInit = posts => {
    setPostCount(posts.length);
  }

  const handleFavPostsInit = posts => {
    setFavPostCount(posts.length);
  }

  //init
  useEffect(() => {

    if (isMe){
      setProfile(userContext.user.profile);
    }else{
      DatabaseAPI.getUserProfile(locationParams?.username)
      .then(function(profile){
        setProfile(profile);
      })
      .catch(function(error){
        setError(error);
      })
    }
  }, [locationParams?.username]);

  //init
  useEffect(() => {
    if (profile === undefined) return;
    DEBUG && console.log("GOT USER PROFILE",profile);
  }, [profile]);

  return(
    error ?
      <ApiErrorMessage error={error}/>
    :
    !profile ?
      <Loader active inline='centered' />
    :
    <Routes>
      <Route
      index
      element={<UserProfile profile={profile}/>}
      />
      <Route
      path="settings/*"
      element={<UserSettingsPage/>}
      isAuthenticated={isMe}
      />
      <Route
      path="tracks/*"
      element={<UserTracks/>}
      />
      <Route
      path=":type/*"
      element={<MusicPosts/>}
      />
    </Routes>
  )

}

export default UserPage;
