import React, {useState,useEffect} from 'react';
import { Button,Modal,Form,Tab,Dimmer,Loader } from 'semantic-ui-react';
import classNames from "classnames";
import PlaylistEditBasic from "./PlaylistEditBasic";
import PlaylistEditSettings from "./PlaylistEditSettings";
import playlistModel from  "../Playlist.Model";
import {isEqualPlaylists} from "../../utils/Utils";

const PlaylistEditModal = (props) => {
  const playlist = props.playlist ? props.playlist : new playlistModel();
  const [editPlaylist,setEditPlaylist] = useState(playlist);
  const [updated,setUpdated] = useState(false);
  const [loading,setLoading] = useState(false);

  const modalTitle = props.newItem ? `Add new playlist` : `Edit playlist`;
  const submitText = props.newItem ? 'Add' : 'Apply';
  const canSubmit = props.newItem ? (editPlaylist.title !== undefined) : updated;

  useEffect(() => {
    const bool = !isEqualPlaylists(editPlaylist,playlist);
    setUpdated(bool);
  }, [editPlaylist]);


  const handleSubmit = (e) => {
    e.preventDefault();

    if(!canSubmit) return;

    const submitFn = props.onSubmit;
    if (typeof props.onSubmit !== 'function') return;

    setLoading(true);

    const promise = props.onSubmit(editPlaylist,playlist)

    Promise.resolve(promise)
    .then(() => {
      props.onClose();
    })
    .finally(function(){
      setLoading(false);
    })

  }

  const handleCancel = (e) => {
    e.preventDefault();
    setEditPlaylist(playlist);
    props.onClose();
  }

  const handlePlaylistTabChange = (playlist) => {
    setEditPlaylist(playlist);
  }

  const panes = editPlaylist ? [
    {
      menuItem: 'Main',
      render: () => <Tab.Pane><PlaylistEditBasic playlist={editPlaylist} onChange={handlePlaylistTabChange}/></Tab.Pane>
    },
    {
      menuItem: 'Settings',
      render: () => <Tab.Pane><PlaylistEditSettings playlist={editPlaylist} onChange={handlePlaylistTabChange}/></Tab.Pane>
    }
  ] : [];

  return(
    <Modal
      id="playlistEditModal"
      as={Form}
      onSubmit={handleSubmit}
      closeIcon
      onClose={props.onClose}
      open={props.open}
      className={classNames({
        loading:false
      })}
    >

      <>
        <Modal.Header>{modalTitle}</Modal.Header>
          <Modal.Content>
            {
              loading &&
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            }
            <Modal.Description>
              <Tab panes={panes} />
            </Modal.Description>
          </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={handleCancel}>Cancel</Button>
          <Button primary type="submit" disabled={!canSubmit}>{submitText}</Button>
        </Modal.Actions>
      </>
    </Modal>
  )

}

export default PlaylistEditModal
