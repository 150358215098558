import React,{useState,useEffect} from "react";
import {
  Route,
  Navigate,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import { Container } from 'semantic-ui-react';
import { ToastContainer,Slide } from 'react-toastify';
import { Grid } from 'semantic-ui-react';

import {APP_VERSION} from "./Constants";

import { LastLocationPath } from "./utils/LastLocationPath";
import { useUser } from "./user/User.Context";
import { DatabaseApiCache } from "./databaseAPI/cache";
import LoginModal from "./user/LoginModal";

import "./App.scss";
import GATracker from "./components/GATracker";
import HomePage from "./pages/home";
import PostPage from "./pages/post";
import MusicPage from "./pages/music/index";
import UserPage from "./pages/user/index";
import WizardPage from "./pages/wizard";
import AdvancedWizard from "./wizard/advanced/AdvancedWizard";
import NotFoundPage from "./pages/404";



import { TopMenu } from "./pages/menu.top";
import MainMenu from "./pages/menu.main";
import UserMenu from "./pages/menu.user";

const AppContent = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;

  //maybe clear local data
  useEffect(() => {
    const localVersion = DatabaseApiCache.getLocalVersion();
    if ( !localVersion || ( APP_VERSION === localVersion ) ) return;
    userContext.userLogout();
    DatabaseApiCache.setLocalVersion();
  }, []);

  //init location
  useEffect(() => {
    //redirect to last viewed path
    if (location.pathname !== '/') return; //only when on home
    const lastLocationPath = LastLocationPath.get();
    if (lastLocationPath){
      console.log("app init - redirect to last stored path",lastLocationPath);
      navigate(lastLocationPath);
    }
  }, []);

  return (
    //https://dev.to/nilanth/how-to-create-public-and-private-routes-using-react-router-72m
    <>
      <GATracker />
      <ToastContainer
      position="bottom-right"
      transition={Slide}
      autoClose={5000}
      hideProgressBar={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="colored"
      />
      <LoginModal/>
      <TopMenu/>
        <Grid className='page-container'>
          <Grid.Column width={3} className='page-menu'>
            <Routes>
              <Route
              path="/user/*"
              element={<UserMenu/>}
              />
              <Route
              path="*"
              element={<MainMenu/>}
              />
            </Routes>
          </Grid.Column>
          <Grid.Column width={13} className='page-content'>
            <Routes>
              {/*redirect from Last.fm callback URL*/}
              <Route
              path='/lastfm-user-token'
              element={<Navigate to={`/user/${myUsername}/settings/lastfm?token=${new URLSearchParams(location.search).get('token')}`} replace />}
              />
              <Route
              path="/"
              element={<HomePage/>}
              />
              <Route path="/music/*"
              element={<MusicPage/>}
              isAuthenticated={userContext.isLogged}
              />

              <Route
              path="/playlist/:post_id/*"
              element={<PostPage/>}
              isAuthenticated={userContext.isLogged}
              />
              <Route
              path="/playlist/:post_id/json/*"
              element={<AdvancedWizard/>}
              />
              <Route
              path="/user/:username/*"
              element={<UserPage/>}
              isAuthenticated={userContext.isLogged}
              />
              <Route
              path="/wizard/*"
              element={<WizardPage/>}
              isAuthenticated={userContext.isLogged}
              />
              <Route
              path="*"
              element={<NotFoundPage/>}
              />
           </Routes>
          </Grid.Column>
        </Grid>
    </>
  );
}

export default AppContent;
