import React,{useState,useEffect} from "react";
import {DEBUG} from "../Constants";
import WizardBox from "../wizard/WizardBox";
import { Loader,Dimmer,Container,Message,Icon } from 'semantic-ui-react';
import {decodePlaylist} from "../utils/Utils";
import { LastLocationPath } from "../utils/LastLocationPath";
import { useLocation,useNavigate,matchRoutes } from "react-router-dom";
import Radio from "../radio/Radio";
import { PlayerProvider } from "../player/Player.Context";
import { ImportersList } from "../wizard/ImportersList";

const WizardPage = (props) => {
  let navigate = useNavigate();
  let location = useLocation();

  const [playlist,setPlaylist] = useState();
  const [loading,setLoading] = useState();

  //initialize playlist when URL changes
  useEffect(() => {
    const getLocationParams = location => {
      const routesMatch = matchRoutes([
        {path:'/wizard/:searchType'},
        {path:'/wizard/load/:encodedString'}
      ], location);
      const match = routesMatch ? routesMatch[0] : undefined;
      return match?.params;
    }
    const locationParams = getLocationParams(location);
    const encodedString = locationParams?.encodedString;

    const playlist = decodePlaylist(encodedString);
    DEBUG && console.log("playlist decoded from URL...",encodedString,playlist);
    setPlaylist(playlist);

  }, [location]);

  const handleUpdate = (playlist,updateCount) => {
    if(updateCount===1){//on init
      console.log("WIZARD / IMPORT SUCCESSFULL!",playlist);
      LastLocationPath.set(location.pathname);
    }
  }

  return (
    <>
      <Message icon>
        <Icon name='cloud download' />
          <Message.Content>
          <Message.Header>Import <small>almost</small> anything!</Message.Header>
            <p>
            All kind of services are supported.  You're welcome.
            </p>
            <ImportersList/>
          </Message.Content>
      </Message>
      <Dimmer.Dimmable as={Container} dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        <WizardBox playlist={playlist}/>
      </Dimmer.Dimmable>
      {
      playlist &&
        <Dimmer.Dimmable as={Container} dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
          <PlayerProvider>
            <Radio
            playlist={playlist}
            onLoading={bool=>setLoading(bool)}
            onUpdate={handleUpdate}
            />
          </PlayerProvider>
        </Dimmer.Dimmable>
      }
    </>
  );
};

export default WizardPage;
