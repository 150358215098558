import React from 'react';

const UserList = (props) => {

  const users = props.users || [];

  return(
    <ul className="users-list">
    {
      users.length &&
      users.map((user,key) => {
        var userImage = user?.image ? 'url('+user.image+')' : '';
        var userLink = '/user/'+user.username;
        return (
          <li
          key={key}
          title={user.name}
          >
            <a
            href={userLink}
            className="cover-img"
            style={{backgroundImage: userImage}}
            >
            </a>
          </li>
        )
      })
    }
    </ul>
  )

}

export default UserList
