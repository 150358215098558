import React from "react";
import { Icon } from 'semantic-ui-react';

const PostCover = (props) => {

  const playlistBackgroundImage = props.image ? 'url('+props.image+')' : '';

  return (
    <>
      {
        props.selected &&
        <div className="playlist-selected">
          <Icon name='check circle'/>
        </div>
      }
        <div className="cover-img" style={{backgroundImage: playlistBackgroundImage}}></div>
    </>
  )
}

export default PostCover;
