//https://gist.github.com/jimode/c1d2d4c1ab33ba1b7be8be8c50d64555
//https://kentcdodds.com/blog/how-to-use-react-context-effectively
//https://gist.github.com/jimode/c1d2d4c1ab33ba1b7be8be8c50d64555
import React, { useReducer,createContext,useEffect,useState,useCallback } from 'react';
import {DEBUG} from "../Constants";
import DatabaseAPI from "../databaseAPI/api";
import { DatabaseApiCache } from "../databaseAPI/cache";
import {findPostByID} from "../utils/Utils";

const UserContext = createContext();

//'undefined' values allows us to check wheter or not we did an API call.
const blankState = {
	token:undefined,
	profile:undefined,
	posts:undefined,
	loading: false,
	errorMessage: null
};

//try to populate browser data
const localState = {
	...blankState,
	token:		DatabaseApiCache.getUserToken() || blankState.token,
	profile: 	DatabaseApiCache.getLocalUser() || blankState.profile,
	posts: 		DatabaseApiCache.getLocalPosts() || blankState.posts
};

function userReducer(state, action){
	DEBUG && console.log('USER REDUCER:',action.type);
	switch(action.type) {

		case 'TOKEN_SUCCESS':

			//store in browser too
			DatabaseApiCache.setUserToken(action.payload);

			return {
				...state,
				token: action.payload
			};

		case 'USERDATA_REQUEST':
			return {
				...state,
				loading: true,
				errorMessage: action.error,
			};
		break;

		case 'USERDATA_SUCCESS':

			//store in browser too
			DatabaseApiCache.setLocalUser(action.payload);

			return {
				...state,
				profile: action.payload,
				loading: false,
			};

		break;

		case 'USERDATA_ERROR':
			return {
				...state,
				loading: false,
				errorMessage: action.error,
			};
		break;

		case 'USERDATA_UPDATE':
			return {
				...state,
				loading: true
			};
		break;

		case 'USERPOSTS_REQUEST':
			return {
				...state,
				loading: true,
				errorMessage: action.error,
			};
		break;

		case 'USERPOSTS_SUCCESS':

			//store in browser too
			DatabaseApiCache.setLocalPosts(action.payload);

			return {
				...state,
				posts: action.payload,
				loading: false
			};

		break;

		case 'USERPOSTS_ERROR':
			return {
				...state,
				loading: false,
				errorMessage: action.error
			};
		break;

		case 'USER_LOGOUT':
			return blankState;
		break;

		case 'SET_USERS_FOLLOWED':
			return {
				...state,
				profile:{
					...state.profile,
					users_followed:action.payload
				}
			}

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};

export function UserProvider({children}){
  const [state, dispatch] = useReducer(userReducer, localState);
	const [favTracks,setFavTracks] = useState();
	const [lastfmLoading,setLastfmLoading] = useState();
	const [isLogged,setIsLogged] = useState(false);

	const addPost = async playlist => {
		return DatabaseAPI.addPost(playlist)
		.then(function(newPlaylist){
			let newPosts = [...state.posts];
			newPosts.push(newPlaylist);
			dispatch({ type: 'USERPOSTS_SUCCESS', payload: newPosts });
			return newPlaylist;
		})
	}

	const updatePost = async playlist => {
		return DatabaseAPI.updatePost(playlist)
		.then(function(newPlaylist){

			//update post in context
			const post_id = newPlaylist.getMetaValue('post_id');

			if (post_id){
				const post = findPostByID(state.posts,post_id);
				const index = state.posts.indexOf(post);

				if (index !== -1){
					updateContextPost(index,newPlaylist);
				}
			}

			return newPlaylist;
		})
	}

	const deletePost = async playlist => {
		return DatabaseAPI.deletePost(playlist)
		.then(function(newPlaylist){
			const post_id = newPlaylist.getMetaValue('post_id');
			const post = findPostByID(state.posts,post_id);
			const index = state.posts.indexOf(post);
			if (index !== -1){
				updateContextPost(index,newPlaylist);
			}
			return newPlaylist;
		})
	}

	const getFavTracksPost = () => {
		const favTracksID = state.profile?.favtracks_id;
		if (!favTracksID) return;
		return findPostByID(state.posts,favTracksID);
	}

	//update fav tracks
	const updateFavTracksPost = newPlaylist => {
		const post = getFavTracksPost()
		const index = state.posts.indexOf(post);
		if (index !== -1){
			updateContextPost(index,newPlaylist);
		}
	}

	//update a single post
	const updateContextPost = (index,post) => {
		if(typeof state.posts[index] === 'undefined') return;
		let newPosts = [...state.posts];
		newPosts[index] = post;
		dispatch({ type: 'USERPOSTS_SUCCESS', payload: newPosts });
		return true;
	}

	const updateFavPosts = ids => {
		const newProfile = {
			...state.profile,
			favpost_ids:ids
		}

		console.log("NEW PROFILE",newProfile);

		dispatch({ type: 'USERDATA_SUCCESS', payload: newProfile });

	}

	const loggedUserUpdate = async(payload) => {

		return DatabaseAPI.updateMyProfile(payload)
		.catch(error => {
			dispatch({ type: 'USERDATA_ERROR', error: error });
		})

	}

	const updateProfile = async(data) => {

		const profile = state.profile;
		const user_id = profile.ID;
		const isLogged = Boolean(state.token);

		console.log("UPDATING USER PROFILE...",user_id,data);

		dispatch({ type: 'USERDATA_UPDATE' });

		let promise;

		//if the user is logged; update DB first
		//else we'll just update the local data.

		if (isLogged){//send to DB first.  This will return the full profile.
			promise = loggedUserUpdate(data);
		}else{
			//fake full profile returned.
			promise = Promise.resolve({
				...profile,
				...data
			});
		}

		return promise
		.then(function(newProfile){
			dispatch({ type: 'USERDATA_SUCCESS', payload: newProfile });
			console.log("UPDATED USER PROFILE",user_id,newProfile);
			return newProfile;
		})


	}

	const userLogout = () => {
		DatabaseApiCache.clearDatabaseApiCache();
		dispatch({ type: 'USER_LOGOUT' });
	}

	const populateMyToken = async(username,password)=>{

		return DatabaseAPI.getUserToken(username,password)
		.then(function(token){
			dispatch({ type: 'TOKEN_SUCCESS', payload: token });
			return token;
		})
	}

	const populateMyProfile = async()=>{

		DEBUG && console.log("POPULATE MY PROFILE");

		dispatch({ type: 'USERDATA_REQUEST' });

		return DatabaseAPI.getMyProfile()
		.then(function(profile){
			dispatch({ type: 'USERDATA_SUCCESS', payload: profile });
			return profile;
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: 'USERDATA_ERROR', error: error });
		})
	}

	//validate token (might have expired)
	//and eventually populate profile
	useEffect(() => {
		if( !state.token ) return;
		DatabaseAPI.validateUserToken()
		.then(function(){
			if (state.profile === undefined){
				populateMyProfile();
			}
		})
		.catch(error => {
			userLogout();
		})
	}, [state.token])

	useEffect(() => {
		const bool = (state.profile?.ID);
		setIsLogged(bool);
	}, [state.profile])

	//populate user posts
	useEffect(() => {
		if (!state.profile) return;
    if (state.posts !== undefined) return;

		const populateMyPosts = async()=>{

			DEBUG && console.log("POPULATE MY POSTS");

			dispatch({ type: 'USERPOSTS_REQUEST' });

			return DatabaseAPI.getPosts({
				posts_per_page:-1,
		    author_name:state.profile.username
			},true)
			.then(function(posts){
				dispatch({ type: 'USERPOSTS_SUCCESS', payload: posts });
			})
			.catch(error => {
				console.error(error);
				dispatch({ type: 'USERPOSTS_ERROR', error: error });
			})
		}

		populateMyPosts();

	}, [state.profile])

	//populate favTracks
	useEffect(()=>{
		const populateFavTracks = () => {
			const favTracksID = state.profile?.favtracks_id;
			if (!favTracksID) return;
			const playlist = findPostByID(state.posts,favTracksID);
			setFavTracks(playlist);
		}

		populateFavTracks();

	}, [state.posts])

	const toggleFavoritePost = async (playlist,bool) => {
    return DatabaseAPI.toggleFavoritePost(playlist,bool)
		.then(function(favPostIDs){
			updateFavPosts(favPostIDs);
			return favPostIDs;
		})
  }

	const toggleFavoriteTrack = async (track,bool) => {
    return DatabaseAPI.toggleFavoriteTrack(track,bool)
		.then(function(newPlaylist){
			updateFavTracksPost(newPlaylist);
			return newPlaylist;
		})
  }

	const toggleFavoriteUser = async (username,bool) => {
    return DatabaseAPI.toggleFavoriteUser(username,bool)
		.then(function(users){
			dispatch({ type: 'SET_USERS_FOLLOWED', payload: users });
			return users;
		})
  }

	const toggleQueueTrack = async (track,playlist,bool) => {

		const playlistID = playlist.getMetaValue('post_id');
		const favTracksID = state.profile?.favtracks_id;

		if (playlistID === favTracksID){
			return toggleFavoriteTrack(track,bool);
		}

    return DatabaseAPI.toggleQueueTrack(track,playlist,bool)
		.then(function(newPlaylist){

			console.log("NEW PLAYLIST",newPlaylist);

			const post = findPostByID(state.posts,playlistID);
			const index = state.posts.indexOf(post);
			if (index !== -1){
				updateContextPost(index,newPlaylist);
			}
			return newPlaylist;
		})
  }

	const filterIsFollowed = username => {
    const user = ( state.profile?.users_followed || []).find(function(user){
      return (user.username === username);
    })
    return (user !== undefined);
  }

	const filterIsFavoritePost = useCallback(
		(playlist)=>{
			const favPostIDs = state.profile?.favpost_ids;
			if (!favPostIDs) return;
	    const post_id = playlist.getMetaValue('post_id');
	    return favPostIDs.includes(post_id);
		},
		[state.profile?.favpost_ids]
	)

	const filterIsFavoriteTrack = useCallback(
		(jspfTrack)=>{
			const myFavTracks = favTracks;
	  	if (!myFavTracks) return false;
	    return myFavTracks.hasTrack(jspfTrack);
		},
		[favTracks]
	)

	const canSavePost = (playlist) => {
		const post_id =  playlist.getMetaValue('post_id');
		if (post_id){
			const author_id = playlist.getMetaValue('private/post_author')?.id;
		  const isMyPost = ( author_id && ( author_id === state.profile?.ID) );
		  return ( ( isMyPost && state.profile?.capabilities.edit_posts) || state.profile?.capabilities.edit_others_posts );
		}else{
			return state.profile?.capabilities.create_posts;
		}
	}

	// NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {
		user:state,
		isLogged:isLogged,
		dispatch:dispatch,
		filterIsFavoritePost:filterIsFavoritePost,
		filterIsFavoriteTrack:filterIsFavoriteTrack,
		filterIsFollowed:filterIsFollowed,
		favTracks:favTracks,
		toggleFavoriteTrack:toggleFavoriteTrack,
		toggleFavoritePost:toggleFavoritePost,
		toggleQueueTrack:toggleQueueTrack,
		toggleFavoriteUser:toggleFavoriteUser,
		addPost:addPost,
		updatePost:updatePost,
		deletePost:deletePost,
		canSavePost:canSavePost,
		updateProfile:updateProfile,
		userLogout:userLogout,
		populateMyToken:populateMyToken,
		//TOUFIX MOVE IN APP CONTEXT ?
		lastfmLoading:lastfmLoading,
		setLastfmLoading:setLastfmLoading
	};

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
