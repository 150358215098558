import React from "react";
import {LIVE_TAG_NAME} from "../Constants";
import { Icon,Label } from 'semantic-ui-react';
import {getJspfMetaValue} from "../utils/Utils";
import { useUser } from "../user/User.Context";
import { useApp } from '../App.Context';
import ShortLink from "../components/ShortLink";
import {
  Link
} from "react-router-dom";

const PostMeta = (props) => {
  return(
    <span className={'post-meta '+ (props.className || '')} title={props.title}>
    {
      props.icon &&
      <span className="post-meta-icon">{props.icon}</span>
    }
    {
      props.label &&
      <span className="post-meta-label">{props.label}</span>
    }
    </span>
  )
}

export const PostListItemMetas = (props) => {

  return (
    <>
    <PostMetaAuthor playlist={props.playlist}/>
    <PostMetaFavoritersShort playlist={props.playlist}/>
    <PostMetaTrackCount playlist={props.playlist}/>
    </>
  )
}

export const PostMetaAuthor = (props) => {
  const name = props.playlist.creator;
  const title = `Posted by ${name}`;
  const username = props.playlist.getMetaValue('private/post_author')?.username;

  let labelContent = <span>{name}</span>;

  if (username){
    const url = `/user/${username}`;
    labelContent = <a href={url}>{labelContent}</a>
  }


  return(
    <>
    {
      props.playlist.creator &&
      <PostMeta
      className="post-meta-author"
      title={title}
      icon={<Icon name='user'/>}
      label={labelContent}
      />
    }
    </>
  )
}

export const PostMetaTags = (props) => {
  const tags = props.playlist.getMetaValue('post_tags') || [];

  return(
    <>
      {
        (tags.length > 0) &&
        <span className="post-meta-tag-list">
          {
            tags.map((tag,key) => {

              return (
                <PostMeta
                key={key}
                className="post-meta-tag"
                icon={<Icon name="tag"/>}
                label={tag}
                />
              )
            })
          }
        </span>
      }
    </>
  )
}

export const PostMetaFavoriters = (props) => {
  const favoriters = props.playlist.getMetaValue('private/post_favoriters') || [];
  return(
    <>
      {
        (favoriters.length > 0) &&
        <span className="post-meta-favoriter-list">
          {
            favoriters.map((favoriter,key) => {
              const url = `/user/${props.playlist.creator}`;
              return (
                <PostMeta
                key={key}
                className="post-meta-favoriter"
                icon={<Icon name="heart"/>}
                label={<a href={url}><span>{favoriter}</span></a>}
                />
              )
            })
          }
        </span>
      }
    </>
  )
}

export const PostMetaFavoritersShort = (props) => {
  const userContext = useUser();
  const isFavorited = userContext.filterIsFavoritePost(props.playlist);
  const favoriters = props.playlist.getMetaValue('private/post_favoriters') || [];
  const favoritersCount = favoriters.length;

  return(
    <>
      {
        (favoriters.length > 0) &&
        <PostMeta
        className="post-meta-favoriters-short"
        icon={<Icon name={isFavorited ? 'heart' : 'heart outline'}/>}
        label={favoriters.length}
        />
      }
    </>
  )
}

export const PostMetaStatus = (props) => {
  const meta = props.playlist.getMetaValue('post_status');

  const getStatusText = key => {
    switch(key){
      case 'publish':
        return 'published'
      break;
      case 'future':
        return 'scheduled'
      break;
      case 'trash':
        return 'deleted'
      break;
      default:
        return key
      break;
    }
  }

  const status = getStatusText(meta);

  return(
    <>
    {
      meta &&
      <PostMeta
      className={`post-meta-status post-meta-status-${meta}`}
      icon={<Icon name='toggle on' />}
      label={status}
      />
    }
    </>
  )
}

export const PostMetaTrackCount = (props) => {
  const loadedTracksCount = props.playlist.track.length;
  const totalTracks = props.playlist.getTotalTrackCount();

  const getCount = () => {
    if ( props.playlist.isLive() ){
      if ( (totalTracks === undefined) && !loadedTracksCount ) return;
      const totalTrackText = (totalTracks === undefined) ? '...' : totalTracks;
      return loadedTracksCount + '/' + totalTrackText;
    }else{
      return totalTracks;
    }
  }

  const text = getCount();

  return(
    <>
    {
      (text !== undefined) &&
      <PostMeta
      className="post-meta-track-count"
      icon={<Icon name='list ol' />}
      label={text}
      />
    }
    </>
  )
}

export const PostMetaLink = (props) => {
  const url = props.playlist?.location;
  if (!url) return;
  return(
    <PostMeta
    className="post-meta-link"
    icon={<Icon name='linkify' />}
    label={<ShortLink url={url} length={20}/>}
    />
  )
}

export const PostMetaRadio = (props) => {
  const appContext = useApp();

  const playlistUrl = props.playlist.getMetaValue('import_url');
  const importer = appContext.getImporterByUrl(playlistUrl);
  const style = importer?.infos.image ? {backgroundImage: 'url('+importer.infos.image+')'} : undefined;

  const getDescription = () => {
    let text = 'This playlist updates automatically';
    if (importer?.infos.name){
      text = text + ` (${importer.infos.name})`;
    }
    text = text + '.';
    return text;
  }

  const getDefaultIconClassName = () => {
    const classes = ['icon','cover-img','default-icon'];
    const stringTags = props.playlist.getMetaValue('post_tags') || [];
    const isLive = stringTags.includes(LIVE_TAG_NAME);

    if (isLive){
      classes.push('radio-icon');
    }else{
      classes.push('rss-icon');
    }

    return classes.join(' ');
  }

  return(
    <>
    {
      props.playlist.isLive() &&
      <PostMeta
      className="post-meta-radio"
      title={getDescription()}
      icon={
        <>
        <div
        className={getDefaultIconClassName()}
        ></div>
        <div
        className="icon cover-img importer-icon"
        style={style}
        ></div>
        </>
      }
      />
    }
    </>
  )
}
