/*index.jsx*/
import React from "react";
import { Container } from "semantic-ui-react";
import { Navigate,useParams } from 'react-router-dom';
import LastfmSettingsSection from "./lastfm";
import ProvidersSettingsSection from "./providers";
import { useUser } from "../../../user/User.Context";


const UserSettingsPage = (props) => {

  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;

  //get username in URL
  let { username} = useParams();

  const hasCap = (username === myUsername);

  return (
    !hasCap ?
      <Navigate to={{ pathname: '/login', state: { from: props.location } }} />
    :
      <Container id="user-settings">
        <ProvidersSettingsSection/>
        <LastfmSettingsSection/>
      </Container>
  );
};

export default UserSettingsPage;
