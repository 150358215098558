import React, { useState,useEffect } from "react";
import { Input,Dropdown } from 'semantic-ui-react';
import DelayedOnChangeComponent from "../../components/DelayedOnChangeComponent";
import playlistModel from  "../../playlist/Playlist.Model";
import { useUser } from "../../user/User.Context";

export const WizardTabLastFm = (props) => {

  const userContext = useUser();

  const lastfmUserOptions = [
    {
      key: 'lastfm-user-loved-tracks',
      text: 'Loved tracks',
      value: 'lastfm-user-loved-tracks'
    },
    {
      key: 'lastfm-user-top-tracks',
      text: 'Top tracks',
      value: 'lastfm-user-top-tracks'
    },
    {
      key: 'lastfm-user-recent-tracks',
      text: 'Recent tracks',
      value: 'lastfm-user-recent-tracks'
    },
    {
      key: 'lastfm-station-recommended',
      text: 'Recommended Station',
      value: 'lastfm-station-recommended'
    },
    {
      key: 'lastfm-station-mix',
      text: 'Mix Station',
      value: 'lastfm-station-mix'
    },
    {
      key: 'lastfm-station-library',
      text: 'Library Station',
      value: 'lastfm-station-library'
    }
  ];
  const [wizardUrl, setWizardUrl] = useState(props?.playlist?.getMetaValue('import_url'));
  const localLastfmUser = userContext.user.profile?.lastfm?.username;

  const [lastfmUser, setLastfmUser] = useState(localLastfmUser);
  const [lastfmType, setLastfmType] = useState('lastfm-station-recommended');

  useEffect(() => {
    const newUrl = buildPlaylistUrl(lastfmUser,lastfmType);
    setWizardUrl(newUrl);
  }, [lastfmUser]);

  useEffect(() => {
    const newUrl = buildPlaylistUrl(lastfmUser,lastfmType);
    setWizardUrl(newUrl);
  }, [lastfmType]);

  const buildPlaylistUrl = (user,type) => {

    let url = '';

    if (user && type){

      url = 'lastfm:user:'+user;

      switch(type){
        case 'lastfm-user-loved-tracks':
          url = url + ':loved';
        break;
        case 'lastfm-user-top-tracks':
          url = url + ':top';
        break;
        case 'lastfm-user-recent-tracks':
          url = url + ':recent';
        break;
        case 'lastfm-station-recommended':
          url = url + ':station:recommended';
        break;
        case 'lastfm-station-mix':
          url = url + ':station:mix';
        break;
        case 'lastfm-station-library':
          url = url + ':station:library';
        break;
      }

    }

    return url;
  }

  useEffect(() => {

    //pass to parent
    //(as a playlist rather than an URL so we might add options).
    const playlist = new playlistModel();
    playlist.setMetaValue('import_url',wizardUrl);

    props.onChange(playlist);

  }, [wizardUrl]);

  return (
    <div>
      <DelayedOnChangeComponent component={
        <Input
          labelPosition='left'
          name="wizard_lastfm_username"
          placeholder='Username'
          defaultValue={lastfmUser}
          onChange={event => setLastfmUser(event.target.value)}
        />
      }/>
      <Dropdown
        placeholder='Type'
        name="wizard_lastfm_type"
        defaultValue={lastfmType}
        options={lastfmUserOptions}
        onChange={(e, { value }) =>{
          setLastfmType(value)
        }}
      />
    </div>
  )
}
