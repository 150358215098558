import React, { useState,useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";

import MusicPosts from "../music/posts";
import UserTracks from "../user/UserTracks";

import { useUser } from "../../user/User.Context";

export const MusicPage = (props) => {
  let navigate = useNavigate();
  let { username,type,page } = useParams();

  return (
    <Routes>
      <Route path=":username">
        <Route
        path="tracks/*"
        element={<UserTracks/>}
        />
        <Route
        path="playlists/*"
        element={<MusicPosts/>}
        />
      </Route>
      <Route exact path='staff/playlists' element={<Navigate to='/music/staff/playlists/favorited' />} />
    </Routes>
  )
}

export default MusicPage;
