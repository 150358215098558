import React, {useState,useEffect} from 'react';
import { Button,Modal,Dimmer,Loader } from 'semantic-ui-react';
import {DEBUG} from "../Constants";
import classNames from "classnames";
import { useUser } from "../user/User.Context";
import PostList from "../playlist/manager/PostList";
import playlistModel from  "../playlist/Playlist.Model";
import trackModel from  "../track/Track.Model";
import PlaylistEditModal from "../playlist/edit/PlaylistEditModal";
import EmptyPostsMessage from "../playlist/manager/EmptyPostsMessage";

//TOUFIX WHAT IF WE QUEUE/DEQUEUE A TRACK ON THE CURRENT PLAYLIST ?
//THE TRACKLIST SHOULD ADAPT AUTOMATICALLY.

const TrackQueueModal = (props) => {
  const [isNewPlaylist,setIsNewPlaylist] = useState(false);
  const userContext = useUser();


  const track = props.track || new trackModel();

  const [playlists,setPlaylists] = useState();
  const [selectedPlaylists,setSelectedPlaylists] = useState();

  const [loading,setLoading] = useState(false);

  const [searchFilter,setSearchFilter] = useState();
  const [newPlaylist,setNewPlaylist] = useState(new playlistModel());

  //populate playlists on init
  useEffect(()=>{
    const playlists = (userContext.user.posts || []).filter(post=>{
      const playlist = new playlistModel(post);
      return !playlist.isLive()
    });
    setPlaylists(playlists);
  },[])

  //populate selected playlists depending on the loaded track
  //TOUFIX quite quirky - we should use an API endpoint to check if a track is part of a playlist ?
  useEffect(()=>{
    if (track) {
      const selected = (playlists || []).filter(function(playlist){
        return playlist.hasTrack(track);
      })
      setSelectedPlaylists(selected);
    }
  },[playlists])

  useEffect(()=>{
    if (playlists !== undefined){
      DEBUG && console.log("INITIALIZED TRACK QUEUE MODAL PLAYLISTS",playlists);
    }
  },[playlists])

  /*
  If we add a new playlist;
  We want to use the PostList filter value as new title.  Just to make things smooth.
  */
  useEffect(()=>{
    setNewPlaylist(
      Object.assign(
        new playlistModel(),
        {
          title:searchFilter
        }
      )
    );
  },[searchFilter])

  const handleQueueTrack = async playlist => {
    setLoading(true);
    let newSelection = [...selectedPlaylists];
    return userContext.toggleQueueTrack(track,playlist,true)
    .then(function(){
      newSelection.push(playlist);
      setSelectedPlaylists(newSelection);
    })
    .finally(function(){
      setLoading(false);
    })
  }

  const handleDequeueTrack = async playlist => {
    setLoading(true);
    let newSelection = [...selectedPlaylists];
    return userContext.toggleQueueTrack(track,playlist,false)
    .then(function(){
      const index = newSelection.indexOf(playlist);
      if (index > -1) {
        newSelection.splice(index, 1);
        setSelectedPlaylists(newSelection);
      }
    })
    .finally(function(){
      setLoading(false);
    })
  }

  const handlePlaylistClick = (playlist) => {
    const queue = !selectedPlaylists.includes(playlist);
    let newSelection = [...selectedPlaylists];

    if(queue){
      handleQueueTrack(playlist);
    }else{
      handleDequeueTrack(playlist);
    }
  }

  const handleSearchFilterUpdate = filter => {
    setSearchFilter(filter);
  }

  const handleAddPlaylist = playlist => {
    return userContext.addPost(playlist)
    .then(function(newPlaylist){
      return handleQueueTrack(newPlaylist);
    })
  }

  const handleNewPlaylistModal = async() => {
    setIsNewPlaylist(true);
  }



  return(
    <>
      {
        isNewPlaylist &&
        <PlaylistEditModal
        newItem={true}
        playlist={newPlaylist}
        open={true}
        onClose={()=>{setIsNewPlaylist(false)}}
        onSubmit={handleAddPlaylist}
        />
      }
      <Modal
        id="trackQueueModal"
        closeIcon
        onClose={props.onClose}
        open={(props.open && !isNewPlaylist)}
        className={classNames({
          loading:false
        })}
      >

        <Modal.Header>Queue Track <em>{track.title}</em> by {track.creator}</Modal.Header>
        <Modal.Content>
          {
            loading &&
            <Dimmer active inverted>
            <Loader />
            </Dimmer>
          }
          <Modal.Description>

            <PostList
            items={playlists}
            selectedItems={selectedPlaylists}
            onNewItem={handleNewPlaylistModal}
            onItemClick={handlePlaylistClick}
            onSearchFilter={handleSearchFilterUpdate}
            layout="list"
            />

            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={props.onClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    </>
  )

}

export default TrackQueueModal
