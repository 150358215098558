//https://blog.microideation.com/2021/11/29/automate-oauth-refresh-token-flow-using-axios-interceptors-in-reactjs-react-native-or-javascript/
//https://blog.liplex.de/axios-interceptor-to-refresh-jwt-token-after-expiration/
//https://gist.github.com/paulsturgess/ebfae1d1ac1779f18487d3dee80d1258

import axios from 'axios';
import { DatabaseApiCache } from "./cache";
import {DATABASE_URL,DATABASE_API_URL,DEBUG} from "../Constants";

// Define the constants
export const DATABASE_API_GET_TOKEN_ENDPOINT = '/jwt-auth/v1/token';
export const DATABASE_API_VALIDATE_TOKEN_ENDPOINT = '/jwt-auth/v1/token/validate';
// Define defaults
const databaseAPI = axios.create({
    baseURL: DATABASE_API_URL,
    headers:{
      'content-type': 'application/json'
    },
    withCredentials:false
});

//intercept request before being sent
databaseAPI.interceptors.request.use(
  function(config){

    const token = DatabaseApiCache.getUserToken();

    if ( !token && (config.withCredentials === true) ){
      throw new axios.Cancel('Missing user token.');
    }

    if (token){
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function(error){
    return Promise.reject(error);
  }
)

  //intercept response
  databaseAPI.interceptors.response.use(
    function(response) {
      return response;
  },function(error){

    const config = error.config;

    if ( error.response ){ // Request made and server responded

      //Handle API Error message
      if (error.response.data.message){
        console.log("DatabaseAPI error message:",error.response.data.message);
      }
    }

    //Not matched, return error
    console.log("DatabaseAPI error",error);
    return Promise.reject(error);

  });
export {databaseAPI};
