import React,{useState} from "react";
import { toast } from 'react-toastify';
import UserFriends from "./friends";
import { useUser } from "../../user/User.Context";
import {getToastErrorMessage} from "../../utils/Utils";
import FavoriteButton from "../../components/FavoriteButton";

const UserProfile = (props) => {

  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;

  const profile = props.profile;
  const userImage = profile.image ? 'url('+profile.image+')' : '';
  const isMe = (profile.username === myUsername);


  const favorited = userContext.filterIsFollowed(profile.username);
  const [favoriting,setFavoriting] = useState();

  const toggleFavoriteUser = async bool => {

    if (bool === undefined) return;

    setFavoriting(true);

    return userContext.toggleFavoriteUser(profile.username,bool)
    .then(function(){
      const message = bool ? 'User successfully favorited.' : 'User successfully unfavorited.';
      toast.success(message);
    })
    .catch(error => {
      toast.error(getToastErrorMessage(error,'Error while toggling status'));
    })
    .finally(function(){
      setFavoriting(false);
    })

  }

  return (
    <div id="user-profile">
      <header>
        <div className="user-image cover-img" style={{backgroundImage: userImage}}></div>
        <div className="user-metas">
          <h1>{profile.name}</h1>
        </div>
        <div className="user-actions">
          {
            !isMe &&
            <FavoriteButton
            favorited={favorited}
            onClick={toggleFavoriteUser}
            loading={favoriting}
            disabled={!userContext.isLogged}
            />
          }
        </div>
      </header>
      {
        profile.description &&
        <p>{profile.description}</p>
      }
      <UserFriends
      followers={profile.users_following}
      followed={profile.users_followed}
      />
    </div>
  )
}

export default UserProfile;
