import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import {useLocation} from "react-router-dom";
import { useUser } from "../../user/User.Context";
import { useApp } from '../../App.Context';

const UserTopMenu = (props) => {

  let location = useLocation();
  const pathname = location.pathname;

  const appContext = useApp();
  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;
  const menuText = userContext.isLogged ? myUsername : 'My Account';

  const myProfilePath = `/user/${myUsername}`;
  const userProfileLink = myUsername ? myProfilePath : '#';

  return(
    <div id="user-menu">
    {
      userContext.isLogged &&
      <span id="user-menu-thumb"></span>
    }
    <Dropdown item text={menuText}>
      <Dropdown.Menu>
        {
        !userContext.isLogged &&
          <>
            <Dropdown.Item onClick={()=>{appContext.setLoginModal(true)}}>Login</Dropdown.Item>
          </>
        }
        {
        userContext.isLogged &&
          <>
            <Dropdown.Item active={pathname === myProfilePath}>
              <Link to={userProfileLink}>Profile</Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={(e)=>userContext.userLogout()}>Logout</Dropdown.Item>
          </>
        }
      </Dropdown.Menu>
    </Dropdown>
    </div>
  )
}

export default UserTopMenu
