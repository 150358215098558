import {
  matchRoutes
} from "react-router-dom";

import _ from 'lodash';

import {
  REACTPLAYER_PROVIDERS,
  DEFAULT_PROVIDER_SORTED_KEYS,
  SOUNDCLOUD_CLIENT_ID,
  SPOTIFY_TRACK_ID_REGEX,
  MUSICBRAINZ_TRACK_ID_REGEX,
  DATABASE_API_URL,
  IMPORT_API_URL
} from "../Constants";
import playlistModel from "../playlist/Playlist.Model";
import trackModel from "../track/Track.Model";

export function filterSpotifyTrackUrl(url) {
  return url.match(SPOTIFY_TRACK_ID_REGEX);
}

function filterMusicbrainzTrackUrl(url) {
  return url.match(MUSICBRAINZ_TRACK_ID_REGEX);
}

//filter track identifiers URLs
export function filterIdentifierUrls(urls){

  let output = [];

  const spotifyUrls = urls.filter(filterSpotifyTrackUrl);
  const musicbrainzUrls = urls.filter(filterMusicbrainzTrackUrl);

  return output.concat(spotifyUrls,musicbrainzUrls);

}

/*
export const getSoundcloudTrackData = (trackUrl) => {

  console.log("GET SOUNDCLOUD DATA FOR" + trackUrl);

  let url = new URL('https://api-widget.soundcloud.com/resolve');
  url.searchParams.append("url", trackUrl);
  url.searchParams.append("format", "json");
  url.searchParams.append("client_id",SOUNDCLOUD_CLIENT_ID);

  const config = {
   method: 'get',
   url: url,
  }

  return axios(config)
  .then(function(response){
    console.log("WINZZ",response);
  })
  .catch((error) => {
    console.log("ERROR GETTING SOUNDCLOUD DATA FOR" + trackUrl,error);
  })

}
*/




export function formatMilliseconds(millis){
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export function getUrlDomain(url){
  if(!url) return;

  var domain;
  //find & remove protocol (http, ftp, etc.) and get domain

  if (url.indexOf("://") > -1) {
    domain = url.split('/')[2];
  }
  else {
    domain = url.split('/')[0];
  }

  //find & remove www
  if (domain.indexOf("www.") > -1) {
    domain = domain.split('www.')[1];
  }

  domain = domain.split(':')[0]; //find & remove port number
  domain = domain.split('?')[0]; //find & remove url params

  return domain;
}

//https://stackoverflow.com/a/10903003/782013
export function shortUrl(url, l){
  if(!url) return;
  l = typeof(l) != "undefined" ? l : 50;
  var chunk_l = (l/2);
  url = url.replace("http://","").replace("https://","");

  if(url.length <= l){ return url; }

  var start_chunk = shortString(url, chunk_l, false);
  var end_chunk = shortString(url, chunk_l, true);
  return start_chunk + "..." + end_chunk;
}

function shortString(s, l, reverse){
    var stop_chars = [' ','/', '&'];
    var acceptable_shortness = l * 0.80; // When to start looking for stop characters
    reverse = typeof(reverse) != "undefined" ? reverse : false;
    s = reverse ? s.split("").reverse().join("") : s;
    var short_s = "";

    for(var i=0; i < l-1; i++){
        short_s += s[i];
        if(i >= acceptable_shortness && stop_chars.indexOf(s[i]) >= 0){
            break;
        }
    }
    if(reverse){ return short_s.split("").reverse().join(""); }
    return short_s;
}

export function getJspfObjectInArrayByKey(array,key){
  return (array || []).find(function(obj){
    const prop = Object.keys(obj)[0];
    return (prop === key);
  });
}

/*JSPF have a lot of props that are an array of objects
Those objects have a single prop; like for metas:

metas = [
  {'post_id':123},
  {'post_name':'foo-bar'}
]

This is a method to get a property by key easily.

*/

export function getJspfObjectValueInArrayByKey(array,key){
  const obj = getJspfObjectInArrayByKey(array,key);
  if (!obj) return;
  return obj[key] ?? undefined;
}

export function setJspfObjectValueInArrayByKey(array,key,value){
  let item = getJspfObjectInArrayByKey(array,key);
  if (item){
    item[key] = value;
  }else{
    item = {[key]:value}
    array.push(item)
  }
}

export function getJspfMetaValue(jspf,key){
  return getJspfObjectValueInArrayByKey(jspf?.meta,key);
}

export function getReactPlayerProviderByKey(needle){
  const providers = REACTPLAYER_PROVIDERS;
  return providers.find( ({ key }) => key === needle );
}

//returns a list of providers keys, sorted based on an input array;
//+ removes unexisting (bad) keys
export function filterDisabledProviderKeys(keys){
  const defaultKeys = DEFAULT_PROVIDER_SORTED_KEYS;
  keys = keys ?? [];

  //remove keys that do not exists in the React Player providers
  return keys.filter(function(key){
    return defaultKeys.includes(key);
  });

}

//returns a list of providers keys, sorted based on an input array;
//+ removes unexisting (bad) keys and appending the missing ones at the end.
export function filterSortedProviderKeys(keys){
  const defaultKeys = DEFAULT_PROVIDER_SORTED_KEYS;

  keys = keys ?? [];

  //remove keys that do not exists in the React Player providers
  keys = keys.filter(function(key){
    return defaultKeys.includes(key);
  });

  //concat custom order + remaining keys
  return keys.concat(defaultKeys.filter(x => !keys.includes(x)));

}

//get providers sorted by input keys
export function getSortedProviders(keys){
  keys = filterSortedProviderKeys(keys);
  return keys.map(key => {
    return getReactPlayerProviderByKey(key);
  });
}


export function getImportersByDomain(importers){
  const output = {};

  (importers || []).forEach(function(importer){

    //domain key

    const domain = getUrlDomain(importer.infos.service_url);

    if ( !output.hasOwnProperty(domain) ){
      output[domain] = {
        image:importer.infos.image,
        services:[]
      }
    }

    //service for domain

    const service = {
      name:   importer.infos.name,
      url:    importer.infos.service_url,
      image:  importer.infos.image
    }

    output[domain].services.push(service);

  })

  return output;
}

//check if an object has only one single property (like in JSPF meta)
export function filterIsSinglePropObj(item){
  //check is an object
  const isObj = ( typeof item === 'object' && !Array.isArray(item) && item !== null);
  if (!isObj) return false;
  //check has only one prop
  var values = Object.values(item);
  var propCount = values.length;
  if (propCount > 1) return false;

  return true;
}

export function findPostByID(posts,post_id){

	if (!post_id){
		throw("Missing 'post_id' parameter.");
	}

	return posts?.find(function(post){
		const local_id = post.getMetaValue('post_id');
		return (local_id === post_id);
	});
}

//https://attacomsian.com/blog/javascript-base64-encode-decode
function encodeUnicodeString(str){
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
  }));
}

function decodeUnicodeString(encoded){
  //https://attacomsian.com/blog/javascript-base64-encode-decode
  // Going backwards: from bytestream, to percent-encoding, to original string.
  //TOUFIX handle errors if string is malformed
  try{
    return decodeURIComponent(atob(encoded).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }catch (error) {
    throw new Error("error decoding string.");
  }
}

//encodes a JSPF to a string that can be used in an URL (eg. for the wizard)
function encodeObject(obj){
  const str = JSON.stringify(obj);
  return encodeUnicodeString(str);
}

function decodeObject(encoded){
  try{
    return JSON.parse(decodeUnicodeString(encoded));
  }catch (error) {
    throw new Error("error decoding object.");
  }
}

export function decodePlaylist(encoded){
  if (!encoded) return;
  try{
    let item = decodeObject(encoded);
    return Object.assign(
      new playlistModel(),
      item
    )
  }catch (error) {
    throw new Error("error decoding playlist.");
  }
}

//encode playlist for the wizard URL
export function getWizardPlaylistUrl(playlist){
  if (!playlist) return;
  const reduced = playlist.toReducedPost();
  return '/wizard/load/' + encodeObject(reduced);
}

export const getRouteUsername = (location) => {

  const routesMatch = matchRoutes([
    {path:'/music/:username/*'},
    {path:'/user/:username/*'}
  ], location);

  const match = routesMatch ? routesMatch[0] : undefined;
  if(!match) return;

  return match?.params?.username;

}

export const isRoutePosts = (location) => {

  const routesMatch = matchRoutes([
    {path:'/music/:username/playlists/*'},
    {path:'/user/:username/playlists/*'}
  ], location);

  const match = routesMatch ? routesMatch[0] : undefined;
  if(!match) return;

  return match ? true : false;

}

export const isRouteFavoritePosts = (location) => {

  const routesMatch = matchRoutes([
    {path:'/music/:username/playlists/favorited/*'},
    {path:'/music/:username/playlists/:playlist_type/favorited/*'},
    {path:'/user/:username/playlists/favorited/*'},
    {path:'/user/:username/playlists/:playlist_type/favorited/*'},
  ], location);

  let match = routesMatch ? routesMatch[0] : undefined;

  return match ? true : false;

}

export const getRoutePlaylistType = (location) => {
  const routesMatch = matchRoutes([
    {path:'/music/:username/playlists/:playlist_type/*'},
    {path:'/user/:username/playlists/:playlist_type/*'},
  ], location);

  const match = routesMatch ? routesMatch[0] : undefined;
  if(!match) return;

  let type = match?.params?.playlist_type;
  return (type === 'favorited') ? undefined : type;

}

export const getErrorType = error => {
  switch(error.config?.baseURL){
    case DATABASE_API_URL:
      return 'database';
    break;
    case IMPORT_API_URL:
      return 'import';
    break;
  }
}

export const getErrorTitle = error => {

  const apiType = getErrorType(error);
  let title = 'Error';

  if (error?.response){// Request made and server responded
    const statusCode = error.response.status;
    const statusText = error.response.statusText;

    title = `[${statusCode}] ${statusText}`;

  } else if (error.request) {//Request was made but no response was received
    title = error.message;
  }

  switch(apiType){
    case 'database':
      title =  `Database API - ${title}`;
    break;
    case 'import':
      title =  `Import API - ${title}`;
    break;
  }

  return title;

}

export const getErrorMessage = error => {
  if (error?.response){// Request made and server responded
    return error.response?.data?.message;
  }
}

export const getToastErrorMessage = (error,title) => {
  const error_message = getErrorMessage(error);
  return error_message ? `${title}: ${error_message}` : `${title}.`;
}

export function isEqualPlaylists(a,b){
  a = Object.assign(new playlistModel(),a);
  b = Object.assign(new playlistModel(),b);
  return ( a.hash === b.hash );
}

export function isEqualTracks(a,b){
  a = Object.assign(new trackModel(),a);
  b = Object.assign(new trackModel(),b);
  return ( a.hash === b.hash );
}

//compare two playlist to see if they have different radio settings.
export function isEqualLiveSettings(a,b){

  a = Object.assign(new playlistModel(),a);
  b = Object.assign(new playlistModel(),b);

  a = a.getLiveSettings();
  b = b.getLiveSettings();

  return _.isEqual(a,b);

}
