import React from "react";
import {DATABASE_API_URL,IMPORT_API_URL} from "../Constants";
import ErrorMessage from "../components/ErrorMessage";
import {getErrorTitle,getErrorMessage} from "../utils/Utils";

const ApiErrorMessage = (props) => {
  const error = props.error;
  const title = getErrorTitle(error);
  const message = getErrorMessage(error);

  return (
    <ErrorMessage
    title={title}
    content={message}
    />
  );
};

export default ApiErrorMessage;
