import React, {useState} from 'react';
import { Button, Modal,Segment,Message,Form,Dimmer,Loader,Icon } from 'semantic-ui-react';
import {DATABASE_API_REGISTER_URL,DATABASE_API_LOSTPASSWORD_URL} from "../Constants";
import { useUser } from "./User.Context";
import { useApp } from '../App.Context';
import ApiErrorMessage from "../components/ApiErrorMessage";

const LoginModal = (props) => {

  const appContext = useApp();
  const userContext = useUser();
  const [loading,setLoading] = useState(false);
  const [username,setUsername] = useState();
  const [password,setPassword] = useState();
  const [error,setError] = useState();

  const handleSetUsername = (event) => {
    const value = event.target.value;
    //this makes the component re-render, and I loose input focus
    setUsername(value);
  }

  const handleSetPassword = (event) => {
    const value = event.target.value;
    setPassword(value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    userContext.populateMyToken(username,password)
    .then(function(resp){
      appContext.setLoginModal(false);
    })
    .catch(function(err){
      if (err.response){
        const code = err.response.data.code;
        switch(code){
          case '[jwt_auth] incorrect_password':
            const lostPwdLink = <a href={DATABASE_API_LOSTPASSWORD_URL} target="_blank" rel="noopener noreferrer">Lost your password?</a>;
            err.response.data.message = <>The password you entered is incorrect.  {lostPwdLink}</>
          break;
          case '[jwt_auth] invalid_username':
            err.response.data.message = <>This user does not exists.  If you are unsure of your username, try your email address instead.</>
          break;
        }
      }

      setError(err);
    })
    .finally(function(){
      setLoading(false);
    })
  }

  const ConnectMessage = () => {
    const backendRegisterLink = () => {
      return (<a href={DATABASE_API_REGISTER_URL} target="_blank" rel="noopener noreferrer">here</a>);
    }

    return(
      <Message icon>
        <Icon name='key' />
        <Message.Content>
          <Message.Header>Connect your SPIFF account !</Message.Header>
          <p>
          Save your favorite tracks, create your own playlist or import them, and much more!
          </p>
          <p>
          Please first register {backendRegisterLink()}, then login with this form.
          </p>
        </Message.Content>
      </Message>
    )
  }

  return(
    <Modal
      as={Form}
      onSubmit={handleSubmit}
      autocomplete="off"
      id="loginModal"
      closeIcon
      onClose={()=>{appContext.setLoginModal(false)}}
      onOpen={()=>{appContext.setLoginModal(true)}}
      open={appContext.loginModal}
    >
      <Modal.Header>Login</Modal.Header>
      <Modal.Content>
        {
          loading &&
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        }
        <Modal.Description>
            {
              error ?
              <ApiErrorMessage error={error}/>
              :
              <ConnectMessage/>
            }
            <Segment>
                <Form.Field>
                  <label>Username (or email)</label>
                  <input
                  name="username"
                  autoComplete="username"
                  placeholder='Username'
                  onChange={handleSetUsername}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Password</label>
                  <input
                  name="password"
                  autoComplete="password"
                  type='password'
                  placeholder='Password'
                  onChange={handleSetPassword}
                  />
                </Form.Field>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={()=>{appContext.setLoginModal(false)}}>Cancel</Button>
        <Button primary type="submit">Login</Button>
      </Modal.Actions>
    </Modal>
  )

}

export default LoginModal
