import { default as providers } from 'react-player/lib/players/index.js';

export const APP_VERSION = '114';//when updated, the local data will be cleared
const IS_LOCAL = (process.env.NODE_ENV !== 'production');
export const DEBUG = IS_LOCAL;
export const PLAYER_ENABLED = true;

export const GA_TRACKING_ID = 'UA-222072211-1';//google analytics

//import API
export const IMPORT_API_KEY = 'oXpdKXhcPDCkE0gm0tVB';
export const IMPORT_API_URL = IS_LOCAL ? 'http://localhost:3000' : 'https://spiff-importer-api.fly.dev';
//database API
export const DATABASE_URL = IS_LOCAL ? 'https://spiff-data.local' : 'https://data.spiff-radio.org';
export const DATABASE_API_URL = DATABASE_URL + '/wp-json';//'https://www.spiff-radio.org';
export const DATABASE_API_REGISTER_URL = DATABASE_URL + '/wp-login.php?action=register';
export const DATABASE_API_LOSTPASSWORD_URL = DATABASE_URL + '/wp-login.php?action=lostpassword';

export const PLAYLIST_POST_TYPE ='sstm_playlist';
export const RADIO_POST_TYPE ='sstm_radio';
export const LIVE_TAG_ID = 161;
export const LIVE_TAG_NAME = 'live';
export const STAFF_USER_ID = 45;
export const STAFF_TRACKS_RADIO_ID = 268;


export const DEFAULT_PLAYLIST_LINK = 'music/staff/tracks';


export const LASTFM_API = 'd8e29fd5f73b86a8b5083b47e17f2052';
export const SPOTIFY_API = 'e17c77860d404a96822f803a54666195';

export const SOUNDCLOUD_CLIENT_ID = 'YxQYlFPNletSMSZ4b8Svv9FTYgbNbM79';//this is the "public" soundcloud client ID, not mine


export const SPOTIFY_TRACK_ID_REGEX = new RegExp("spotify\.com\/track\/(.*)");
export const MUSICBRAINZ_TRACK_ID_REGEX = new RegExp("musicbrainz\.org\/recording\/(.*)");



//https://github.com/cookpete/react-player/blob/master/src/players/index.js
export const REACTPLAYER_PROVIDERS = providers;
export const REACTPLAYER_PROVIDER_KEYS = Object.values(providers).map(provider => {return provider.key});

const getDefaultSortedProviderKeys = () => {
  const frontKeys = ['file'].filter(x => REACTPLAYER_PROVIDER_KEYS.includes(x));//the keys we want to put in front (remove the ones that does not exists in the original array)
  const backKeys = REACTPLAYER_PROVIDER_KEYS.filter(x => !frontKeys.includes(x));//
  return frontKeys.concat(backKeys);
}

const getDefaultDisabledProviderKeys = () => {
  return [].filter(x => REACTPLAYER_PROVIDER_KEYS.includes(x));//the keys we want to disable (remove the ones that does not exists in the original array)
}

export const DEFAULT_PROVIDER_SORTED_KEYS = getDefaultSortedProviderKeys();
export const DEFAULT_PROVIDER_DISABLED_KEYS = getDefaultDisabledProviderKeys();
