/*index.jsx*/
import React, { useState,useEffect,useRef } from "react";
import classNames from "classnames";
import PostListItemTile from "./PostListItemTile";
import PostListItemRow from "./PostListItemRow";

import PostListFilters from "./PostListFilters";
import EmptyPostsMessage from "./EmptyPostsMessage";
import { DatabaseApiCache } from "../../databaseAPI/cache";
import { useApp } from '../../App.Context';

const PostListItems = props => {
  const appContext = useApp();
  const [filteredPlaylists, setFilteredPlaylists] = useState(props.items);

  //filter
  useEffect(() => {
    const doFilterPlaylists = (items,filter) => {
      if(filter){
        items = items.filter(function(PlaylistObj){
          const title = PlaylistObj.title.trim().toUpperCase();
          return title.includes(filter.trim().toUpperCase());
        });
      }
      return items;
    }
    const filtered = doFilterPlaylists(props.items,props.filter);
    setFilteredPlaylists(filtered);
  }, [props.items,props.filter]);
  const renderLayout = layoutName => {

    const getLayoutComponent = layoutName => {

      switch(layoutName){
        case 'grid':
          return PostListItemTile;
        break;
        case 'list':
        default:
          return PostListItemRow;
      }

    }

    const LayoutComponent = getLayoutComponent(layoutName);

    return(
        <ul data-playlist-layout={layoutName}>
          {
            (filteredPlaylists || [] ).map((playlist,key) => {

              const isSelected = props.selectedItems?.includes(playlist);
              let isImporterEnabled = true;

              if ( playlist.isLive() ){
                const playlistUrl = playlist.getMetaValue('import_url');
                const importer = appContext.getImporterByUrl(playlistUrl);

                if (importer?.infos.enabled === false){
                  isImporterEnabled = false;
                }

              }

              const handlePlaylistClick = e => {
                if (typeof props.onItemClick === 'function'){
                  e.preventDefault();
                  return props.onItemClick(playlist);
                }
              }

              return (
                <li
                key={key}
                className={classNames({
                  'playlist-item':true,
                  'live-playlist':playlist.isLive(),
                  'disabled-importer':!isImporterEnabled
                })}
                >
                  <LayoutComponent
                  onClick={handlePlaylistClick}
                  playlist={playlist}
                  selected={isSelected}
                  />
                </li>
              )
            })
          }
        </ul>
    )
  }

  return(
    <div className="playlist-manager-section">
      <div className="playlist-manager-section-content">
      {
        (filteredPlaylists?.length !== 0 ) ?
          renderLayout(props.layout)
        :
        props.noItems ?
          props.noItems
          :
          <EmptyPostsMessage/>
      }

      </div>
    </div>

  )
}

const PostList = props => {

  const firstUpdate = useRef(true);
  const [layout, setLayout] = useState(props.layout ?? DatabaseApiCache.getLocalPlaylistLayout() ?? 'grid');
  const [searchFilter, setSearchFilter] = useState(undefined);
  const sections = props.sections || [];

  useEffect(() => {
    if (typeof props.onSearchFilter === 'function') {
      props.onSearchFilter(searchFilter);
    }
  }, [searchFilter]);

  useEffect(() => {
    if (firstUpdate.current) return; //ignore on init
    if (layout){
      console.log("LAYOUT UPDATED",layout);
      DatabaseApiCache.setLocalPlaylistLayout(layout);
    }
  }, [layout]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  });

  return(
    <div className="playlist-manager">
      <PostListFilters
      items={props?.items}
      layout={layout}
      onLayout={(layout)=>setLayout(layout)}
      onSearchFilter={(filter)=>setSearchFilter(filter)}
      onNewItem={props.onNewItem}
      onToggleFilters={props.onToggleFilters}
      />
      <div className="playlist-manager-content">
        <PostListItems
        layout={layout}
        filter={searchFilter}
        items={props?.items}
        selectedItems={props?.selectedItems}
        onItemClick={props?.onItemClick}
        noItems={props.noItems}
        />
      </div>
    </div>
  )
}

export default PostList;
