import "./Radio.scss";
import React, { useState,useRef,useEffect } from "react";
import { Icon,Message } from 'semantic-ui-react';
import {DEBUG} from "../Constants";
import { toast } from 'react-toastify';
import playlistModel from  "../playlist/Playlist.Model";
import {isEqualLiveSettings,getToastErrorMessage,isEqualPlaylists} from "../utils/Utils";
import {isEqualRadioComponent} from "../utils/EqualComponents";
import ImportAPI from "../importAPI/api";
import ApiErrorMessage from "../components/ApiErrorMessage";
import RadioPagination from "./RadioPagination";
import Playlist from "../playlist/Playlist";

const Radio = (props) => {
  const [playing, setPlaying] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [apiError, setApiError] = useState();
  const [playlist,setPlaylist] = useState();
  const inputPlaylist = useRef();
  const prevPage = useRef();

  const [indices,setIndices] = useState();

  const AwaitingRadioTracklist = props => {
    return (
      <>
      {
        (apiError && !pageLoading) ?
        <ApiErrorMessage error={apiError}/>
        :
        <Message icon>
          <Icon name='circle notch' loading />
          <Message.Content>
            <Message.Header>Loading...</Message.Header>
          </Message.Content>
        </Message>
      }
      </>
    )
  }

  //check that request is different from last query
  useEffect(()=>{

    const changed = !isEqualLiveSettings(props.playlist,inputPlaylist.current);

    if (changed){
      inputPlaylist.current = props.playlist;
      setApiError();
      populateRadioPage();
    }

  },[props.playlist]);

  //pass loading status to parent
  useEffect(()=>{
    if (typeof props.onLoading === 'function') {
      props.onLoading(pageLoading);
    }
  },[pageLoading])

  /*
  Create a new playlist based on the initial radio data;
  Add the page fetched
  */
  const fillPlaylistWithPage = (playlist,page) => {

    //TOUFIX TOUCHECK URGENT
    //how do we handle page data (title, annotation, ...) vs post data ?

    let newPlaylist =
    Object.assign(
      new playlistModel(),
      playlist,
      //add page tracks
      {
        title:page.title,
        annotation:page.annotation,
        track:playlist.track.concat(page.track)
      }
    )

    //update metas from importer
    const pagePlaylist = new playlistModel(page);
    const pageMetas = pagePlaylist.getMetas();

    Object.keys(pageMetas).forEach(key => {
      newPlaylist.setMetaValue(key,pageMetas[key]);
    });

    return newPlaylist;
  }

  const populateRadioPage = async isNext => {

    //pagination must have been updated so use the current playlist if it exists.
    const radioPlaylist = isNext ? playlist : inputPlaylist.current;

    setPageLoading( true );
    setApiError();

    //get remote playlist
    return ImportAPI.getRadioPage(radioPlaylist,isNext)
    .then(function(page){

      if (!page.track.length){
        throw new Error("No tracks found.");
      }

      //check for unchanged data
      const fillPromise = new Promise((resolve, reject) => {
        if ( isEqualPlaylists(page,prevPage.current) ){
          reject('Remote playlist has no changes.');
        }else{
          resolve( fillPlaylistWithPage(radioPlaylist,page) );
        }
      })

      fillPromise
      .then(function(newPlaylist){
        prevPage.current = page;
        setPlaylist(newPlaylist);
        return newPlaylist;
      })
      .catch(error => {
        toast.warn(error);
        console.info(error);
      })

    })
    .catch(error => {
      console.log("LOAD RADIO PAGE ERROR",error);
      toast.error(getToastErrorMessage(error,'Error loading playlist'));
      setPlaying(false);
      setApiError(error);
    })
    .finally(function(){
      setPageLoading(false);
    })

  }

  const handleReload = async() => {
    console.log("RADIO / RELOAD");
    populateRadioPage();
  }

  const handleNext = () => {
    console.log("RADIO / NEXT");
    populateRadioPage(true);
  }

  const handleEnded = () => {

    const paginationMeta = playlist.getMetaValue('import_api/pagination');

    console.log("RADIO / ENDED");
    setPlaying(false);

    const nextPageParams  = paginationMeta?.next;
    const hasNextPage = ( nextPageParams !== undefined );

    if (hasNextPage){
      const oldTrackCount = playlist.track.length
      const newTrackIndex = oldTrackCount;
      console.log("RADIO / LOAD AND PLAY NEXT PAGE TRACK#",newTrackIndex);

      return populateRadioPage(true)
      .then(function(playlist){
        setIndices(newTrackIndex);
        setPlaying(true);
      })
    }else{
      handleReload()
      .finally(function(){
        setIndices(0);
        setPlaying(true);
      })
    }

  }

  //our two Playlist components have the same 'key', so they are considered as one same element.
  //we could give them a different key, but then the page glitches when  it load.
  //instead of this, pass updateCount-1 to the parent so it matches the regular Playlist component behaviour,
  //where when updateCount is 1, the playlist has initialized.
  const handleUpdate = (playlist,updateCount) => {
    if (typeof props.onUpdate === 'function') {
      props.onUpdate(playlist,updateCount-1);
    }
  }

  return (
    <>
    {
      inputPlaylist.current &&
      <>
        {
          //radio loaded
          playlist ?
          <>
            <Playlist
            playlist={playlist}
            action={props.action}
            indices={indices}
            playing={playing}
            onPlaylistEnded={handleEnded}
            onReload={handleReload}
            onUpdate={handleUpdate}
            />
            <RadioPagination
            disabled={pageLoading}
            playlist={playlist}
            onReload={handleReload}
            onNext={handleNext}
            />
          </>
          :
          //awaiting page
          <Playlist
          playlist={inputPlaylist.current}
          emptyTracklist={<AwaitingRadioTracklist/>}
          />
        }

      </>
    }
    </>
  )
}

//export default React.memo(Radio,isEqualRadioComponent);
export default Radio;
