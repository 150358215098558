import React, { useState,useEffect } from "react";
import { MaterialLayoutRenderer } from '@jsonforms/material-renderers';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Hidden,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { rankWith, uiTypeIs,schemaMatches,schemaSubPathMatches,and,toDataPathSegments,isObjectControl } from '@jsonforms/core';
import { Icon } from 'semantic-ui-react';
import _ from 'lodash';



/*
export const SelectorControlTester = rankWith(
  1000,
  schemaMatches(schema => {
    if (!schema.hasOwnProperty('customRender')) return false;
    var cellschema: any = schema;
    var bool = (cellschema['customRender'] === 'rating');
    return bool;
  })
);
*/

//export const SelectorControlTester = rankWith(1000, uiTypeIs('Group'));

export const SelectorControlTester = rankWith(
  3,
  and(
    isObjectControl,
    schemaMatches(schema => {
      if ( !schema.hasOwnProperty('properties') ) return;
      return ( schema.properties.hasOwnProperty('path') && schema.properties.hasOwnProperty('regex') )
    }),
  )
);

const SelectorControlRenderer = (props) => {
  const { uischema, schema, path, visible, renderers } = props;

  const [expanded,setExpanded] = useState(uischema.hasErrors);

  useEffect(() => {
    setExpanded(uischema.hasErrors);
  },[uischema.hasErrors])

  //get JSON fragment based on scope
  const scope = uischema.scope;
  const scopePath = scope.substring(2).split('/');
  const fragment = _.get(schema, scopePath);

  const iconName = uischema.hasErrors ? 'close' : 'checkmark'

  const layoutProps = {
    elements: uischema.elements,
    schema: schema,
    path: path,
    direction: 'column',
    visible: visible,
    uischema: uischema,
    renderers: renderers,
  };
  return (
    <Hidden xsUp={!visible}>
      <Accordion expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} onClick={e=>setExpanded(!expanded)}>

          <Typography><Icon name={iconName}/>{fragment.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MaterialLayoutRenderer {...layoutProps} />
        </AccordionDetails>
      </Accordion>
    </Hidden>
  );
};

export default withJsonFormsLayoutProps(SelectorControlRenderer);
