import React, {useState,useEffect} from 'react';
import { Button,Modal,Form,Tab } from 'semantic-ui-react';
import classNames from "classnames";
import TrackEditBasic from "./TrackEditBasic";
import TrackEditDetails from "./TrackEditDetails";
import TrackEditLinks from "./TrackEditLinks";
import trackModel from  "../Track.Model";

const TrackEditModal = (props) => {
  const track = (props.track !== undefined) ? props.track : new trackModel();
  const [updatedTrack,setUpdatedTrack] = useState(track);
  const [updated,setUpdated] = useState(false);
  const isFirst=(props.index === 0);
  const isLast=(props.index === (props.trackCount - 1));
  const showPreviousBt = (typeof props.onPrevious === 'function');
  const showNextBt = (typeof props.onNext === 'function');

  useEffect(() => {
    const bool = ( JSON.stringify({...updatedTrack}) !== JSON.stringify({...track}) );
    setUpdated(bool);
  }, [updatedTrack]);

  const handleCancel = (e) => {
    e.preventDefault();
    setUpdatedTrack(track);
    props.onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!updated) return;

    /*
    handle edited URLs
    */
    const actual_urls = track.getAllUrls();
    const edited_urls = updatedTrack.getMetaValue('private/edited_urls');

    if (edited_urls){

      const removed_urls = actual_urls.filter(x => !edited_urls.includes(x));
      const new_urls = edited_urls.filter(x => !actual_urls.includes(x));

      updatedTrack.removeUrls(removed_urls);
      updatedTrack.addUrls(new_urls,'manual');
    }

    props.onSubmit(updatedTrack);
    props.onClose();
  }

  const handleUpdateJspf = (jspf) => {
    setUpdatedTrack(new trackModel(jspf));
  }

  const handlePrevious = e => {
    props.onPrevious();
  }

  const handleNext = e => {
    props.onNext();
  }

  const modalTitle = props.newItem ? `Add new track` : `Edit Track`;
  const submitText = props.newItem ? 'Add' : 'Apply';

  return(
    <Modal
      as={Form}
      onSubmit={handleSubmit}
      id="trackEditModal"
      closeIcon
      onClose={props.onClose}
      open={props.open}
      className={classNames({
        loading:false
      })}
    >
      <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
                <Tab panes={[
                  {
                    menuItem: 'Main',
                    render: () => <Tab.Pane><TrackEditBasic track={updatedTrack} newItem={props.newItem} onUpdateJspf={handleUpdateJspf}/></Tab.Pane>
                  },
                  {
                    menuItem: 'Details',
                    render: () => <Tab.Pane><TrackEditDetails track={updatedTrack} newItem={props.newItem} onUpdateJspf={handleUpdateJspf}/></Tab.Pane>
                  },
                  {
                    menuItem: 'Links',
                    render: () => <Tab.Pane><TrackEditLinks track={updatedTrack} newItem={props.newItem} onUpdateJspf={handleUpdateJspf}/></Tab.Pane>
                  }
                ]} />
          </Modal.Description>
        </Modal.Content>
      <Modal.Actions>

        {
          showPreviousBt &&
          <Button type="button" icon="chevron left" onClick={handlePrevious} disabled={isFirst}/>
        }
        {
          showNextBt &&
          <Button type="button" icon="chevron right" onClick={handleNext} disabled={isLast}/>
        }
        <Button type="button" onClick={handleCancel}>Cancel</Button>
        <Button primary type="submit" disabled={!updated}>{submitText}</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TrackEditModal
