import {RADIO_POST_TYPE} from "../Constants";
import trackModel from  "../track/Track.Model";

import {
  setJspfObjectValueInArrayByKey
} from "../utils/Utils";

import md5 from 'md5';
import _ from 'lodash';


export default class playlistModel {
  constructor(jspf){

    //those are the regular JSPF props.
    //use nothing else than that.
    this.title=        null;
    this.creator=      null;
    this.annotation=   null;
    this.info=         null;
    this.location=     null;
    this.identifier=   null;
    this.image=        null;
    this.date=         null;
    this.license=      null;
    this.attribution=  [];
    this.link=         [];
    this.meta=         [];
    this.extension=    [];
    this.track=        [];

    if (jspf){

      /*SANITIZE DATA*/
      //unset value if it is not an array type
      const needArrayProps = ['attribution','link','meta','extension','track'];
      needArrayProps.forEach(function(prop){
        if ( jspf[prop] && !Array.isArray(jspf[prop]) ){
          delete jspf[prop];
        }
      });


      for (const [prop, value] of Object.entries(jspf)) {
        if ( !this.hasOwnProperty(prop) ) continue;
        const initial = this[prop];
        this[prop] = value || initial;
      }
    }

    //build tracks
    this.track = this.track.map((jspf,i)=>{
      jspf.trackNum = i + 1;
      return new trackModel(jspf);
    });

  }

  get hash(){
    return md5(JSON.stringify({...this}));
  }

  get private(){
    const status = this.getMetaValue('post_status');
    return (status === 'private');
  }

  set private(bool){
    const status = bool ? 'private' : 'publish';
    this.setMetaValue('post_status',status);
  }

  getMetas(){

    let output = {};

    this.meta.forEach(function(jspfMeta) {
      const key = Object.keys(jspfMeta)[0];
      const value = Object.values(jspfMeta)[0];
      output[key] = value;
    });

    return output;

  }

  getMetaValue(key){
    return this.getMetas()[key];
  }

  //set meta value by key
  setMetaValue(key,value){
    return setJspfObjectValueInArrayByKey(this.meta,key,value);
  }

  deleteMetaValue(key){
    //remove private metas
    this.meta = this.meta.filter(function(meta){
      const prop = Object.keys(meta)[0];
      return prop !== key;
    })
  }

  filterReducedMetas(){
    //remove private metas
    this.meta = this.meta.filter(function(meta){
      const prop = Object.keys(meta)[0];
      return !prop.startsWith("private/");
    })

  }

  isLive(){
    return ( this.getMetaValue('post_type') === RADIO_POST_TYPE )
  }

  getTotalTrackCount(){

  	if ( this.isLive() ){
  		const paginationMeta = this.getMetaValue('import_api/pagination');
  		const total =  paginationMeta?.total_tracks;
      return (total !== null) ? total : undefined;//TOUFIX TOUCHECK return undefined directly in API ?
  	}else{
  		return this.getMetaValue('private/track_count');
  	}
  }

  getPostUrl(){
    let url;
    const post_name = this.getMetaValue('post_name');
    const post_id = this.getMetaValue('post_id');

    if (post_id){
      url = `/playlist/${post_id}`;
    }

    if (post_name){
      url = url + `/${post_name}`;
    }

    return url;
  }

  //checks if a track exists in a playlist
  hasTrack(trackObj){

    const hash = trackObj.hash;
    if (!hash) return;

    //build an array of unique track IDs
    const allHashes = this.track.map(function(trackObj) {
      return trackObj.hash;
    })
    return allHashes.includes(hash);
  }

  //return a new object with only the properties that does not equals the defaults.
  //used for wizard URLs.
  toReducedPost(){

    //clone
    let reduced = Object.assign(
      new playlistModel(),
      this
    );

    //remove date
    delete reduced.date;

    //remove private metas
    reduced.filterReducedMetas();

    //reduce tracks
    reduced.track = reduced.track.map(function(track){
      return track.toReducedPost();
    })

    const defaults = {...new playlistModel()};

    for (const [prop, value] of Object.entries(defaults)) {

      const a = value;
      const b = reduced[prop];

      if (_.isEqual(a,b)){
        delete reduced[prop];
      }

    }

    return reduced;
  }

  //returns a new playlist that keeps only properties related to a radio.
  //used to know if a radio has been updated (could be saved or not).
  toReducedLivePost(){

    //keep only radio metas
    const metaKeysToKeep = ['import_url','import_options'];

    const radio = Object.assign(
      new playlistModel(),
      {
        title:this.title,
        annotation:this.annotation,
        meta:this.meta.filter(function(meta){
          const prop = Object.keys(meta)[0];
          return metaKeysToKeep.includes(prop);
        })
      }
    );

    return radio.toReducedPost();

  }

  getLiveSettings(){
    return {
      'import_url':     this.getMetaValue('import_url'),
      'import_options': this.getMetaValue('import_options')
    }
  }


}
