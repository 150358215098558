import React from "react";
import {DEBUG} from "../Constants";
import {importAPI} from "./connect"
import playlistModel from "../playlist/Playlist.Model";
import trackModel from "../track/Track.Model";

class ImportAPI extends React.Component {

  static async getImporters(){

    return importAPI.request({
      method: 'get',
      url: '/v2/importers/'
    })
    .then(resp => resp.data)
  }

  static async getDebug(url,options){

    const config = {
      method: 'post',
      url: '/v2/debug/',
  		withCredentials:true,
      data:{
        url:      url,
        options:  options
      }
    }

    return importAPI.request(config)
    .then(resp => resp.data)
  }

  static async getImporterByUrl(url,options){

    if (!url){
      throw new Error("Missing URL.");
    }

    return importAPI.request({
      method: 'get',
      url: '/v2/importer/',
      params: {
        url:      url,
        options:  options
      },
    })
    .then(resp => resp.data.importer)
    .then(function(importer){
      if (!importer){
        throw new Error('No importer found for this URL.');
      }else{
        return importer;
      }
    })
  }

  static async getRadioPage(playlistObj,nextPage){

    const url = playlistObj.getMetaValue('import_url');
    let options = playlistObj.getMetaValue('import_options');//TOUFIX URGENT this MUST be an object!
    if ( Array.isArray(options) ){ //temporary fix, we should remove this after WP plugin is updated
      DEBUG && console.log("import options cannot be an array",options);
      options = undefined;
    }
    const paginationMeta = playlistObj.getMetaValue('import_api/pagination');

    const currentPage = paginationMeta?.current_page;

    let pagination = {
      page:1
    }

    if (currentPage && nextPage){
      pagination = {
        ...paginationMeta?.next,
        page:currentPage + 1
      }
    }

    DEBUG && console.info("GET RADIO PAGE",pagination,url,options,pagination);


    return this.importPlaylist(url,options,pagination)
  }

  static async importPlaylist(url,options,pagination){

  	options = options || {};

  	const config = {
      method: 'post',
      url: '/v2/playlist/import/',
  		withCredentials:true,
      data:{
        url:      url,
        options:  options,
        pagination: pagination
      }
    }

  	DEBUG && console.info("IMPORT PLAYLIST FROM URL",url,pagination.page,config);

    const promise = importAPI.request(config);

    //DEMO CONTENT - every track has a source
    const promiseB = new Promise((resolve, reject) => {
      const jspf = {
        "title":"Codename O.",
        "creator":"gordie",
        "annotation":"test",
        "info":null,
        "track":[
           {
              "title":"Premier cri",
              "creator":"Olive Gréant",
              "location":[
                 "https://www.spiff-radio.org/wordpress/wp-content/uploads/2021/02/accouchement-olive-premier-cri.mp3"
              ]
           },
           {
              "title":"Take It Easy",
              "creator":"Hopeton Lewis",
              "identifier":[
                "https://musicbrainz.org/recording/4cdf1775-6834-415c-b384-ac3f19174124",
                "https://open.spotify.com/track/7M8nUdtIbUSsIHqFqUUDcU",
              ],
              "location":[
                "https://soundcloud.com/jimmy-bo-horne/you-get-me-hot",
                "https://soundcloud.com/hopeton-lewis/take-it-easy-3",
                 "https://www.youtube.com/watch?v=7pnuW8V8obc",
              ],
              "link":[
                "https://fr.wikipedia.org/wiki/Hopeton_Lewis",
                "https://www.discogs.com/fr/Hopeton-Lewis-Take-It-Easy/release/8119396"

              ]
           },
           {
              "title":"Perdue dans mon ventre",
              "creator":"Carl et les hommes-boîtes",
              "link":[
                 "https://www.youtube.com/watch?v=kfqa_K67pm4"
              ]
           },
           {
              "title":"Khawuleza (Hurry, Mama, Hurry!)",
              "creator":"Miriam Makeba",
              "link":[
                 "https://www.youtube.com/watch?v=Gwon_9TA0O0"
              ]
           },
           {
              "title":"Everybody Loves The Sunshine",
              "creator":"Roy Ayers",
              "link":[
                 "https://www.youtube.com/watch?v=nC9dQOnUyao"
              ]
           },
           {
              "title":"Mamma A Go-Go",
              "creator":"PEREZ PRADO",
              "link":[
                 "https://www.youtube.com/watch?v=lTcggHapBxI"
              ]
           },
           {
              "title":"Forest Nativity",
              "creator":"Francis Bebey",
              "link":[
                 "https://www.youtube.com/watch?v=o7Uygj-bP1c"
              ]
           },
           {
              "title":"Baba Boom Time",
              "creator":"The Jamaicans",
              "link":[
                 "https://www.youtube.com/watch?v=bqcuiiwHCYg"
              ]
           },
           {
              "title":"It's Oh So Quiet",
              "creator":"Björk",
              "link":[
                 "https://www.youtube.com/watch?v=gLF-_Z8TK1k"
              ]
           },
           {
              "title":"She's a rainbow",
              "creator":"The Rolling Stones",
              "link":[
                 "https://www.youtube.com/watch?v=6c1BThu95d8"
              ]
           },
           {
              "title":"Coconut Mango",
              "creator":"Arrangement",
              "link":[
                 "https://www.youtube.com/watch?v=B_qCb51zjdQ"
              ]
           },
           {
              "title":"Here Comes the Sun",
              "creator":"The Beatles",
              "link":[
                 "https://www.youtube.com/watch?v=KQetemT1sWc"
              ]
           },
           {
              "title":"Piece Of My Heart",
              "creator":"Big Brother & the Holding Company",
              "link":[
                 "https://www.youtube.com/watch?v=3SL0oRcD7t0"
              ]
           },
           {
              "title":"I Only Have Eyes For You",
              "creator":"The Flamingos",
              "link":[
                 "https://www.youtube.com/watch?v=1_urvud-Oi0"
              ]
           },
           {
              "title":"Baby I love you so",
              "creator":"Jacob Miller",
              "link":[
                 "https://www.youtube.com/watch?v=pJ-B2gn9c60"
              ]
           },
           {
              "title":"Simply Beautiful",
              "creator":"Al Green",
              "link":[
                 "https://www.youtube.com/watch?v=yOxFl4dna3o"
              ]
           },
           {
              "title":"It's Your Body 5",
              "creator":"Connan Mockasin",
              "link":[
                 "https://www.youtube.com/watch?v=lu4VJ9reOOE"
              ]
           },
           {
              "title":"Golden Hours",
              "creator":"Brian Eno",
              "link":[
                 "https://www.youtube.com/watch?v=Sxyg3sP03Cs"
              ]
           },
           {
              "title":"My Cherie Amour",
              "creator":"Stevie Wonder",
              "link":[
                 "https://www.youtube.com/watch?v=Fjufjv4rH0s"
              ]
           },
           {
              "title":"Mother Stands For Comfort",
              "creator":"Kate Bush",
              "link":[
                 "https://www.youtube.com/watch?v=n_hpZSkQ30Y"
              ]
           },
           {
              "title":"La Luna en Tu Mirada",
              "creator":"Los Zafiros",
              "link":[
                 "https://www.youtube.com/watch?v=-yv3-X665UY"
              ]
           },
           {
              "title":"Moonchild",
              "creator":"King Crimson",
              "link":[
                 "https://www.youtube.com/watch?v=Du4oaSfxYu4"
              ]
           },
           {
              "title":"Wilson Rag",
              "creator":"Elisabeth Cotten",
              "link":[
                 "https://www.youtube.com/watch?v=4OfP3oKeknw"
              ]
           },
           {
              "title":"BAM BAM",
              "creator":"Sister Nancy",
              "link":[
                 "https://www.youtube.com/watch?v=qXnT3LFTc-s"
              ]
           },
           {
              "title":"Ain't Got No, I Got Life",
              "creator":"Nina Simone",
              "link":[
                 "https://www.youtube.com/watch?v=L5jI9I03q8E"
              ]
           },
           {
              "title":"Second Fiddle",
              "creator":"Tommy McCook",
              "link":[
                 "https://www.youtube.com/watch?v=MWrsyFGz3PI"
              ]
           },
           {
              "title":"Nice Things",
              "creator":"Tank And The Bangas",
              "link":[
                 "https://www.youtube.com/watch?v=Hp8QF8yhPpU"
              ]
           },
           {
              "title":"It's A Beautiful Morning",
              "creator":"The Rascals",
              "link":[
                 "https://www.youtube.com/watch?v=cJqjlFGZxtE"
              ]
           },
           {
              "title":"I Say A Little Prayer",
              "creator":"Aretha Franklin",
              "link":[
                 "https://www.youtube.com/watch?v=TDyiREoBw0o"
              ]
           },
           {
              "title":"De cara a la Pared",
              "creator":"Lhasa de Sela",
              "link":[
                 "https://www.youtube.com/watch?v=k_N_FQuSeuo"
              ]
           },
           {
              "title":"With a Girl Like You",
              "creator":"The Troggs",
              "link":[
                 "https://www.youtube.com/watch?v=3bCGacIl2f0"
              ]
           },
           {
              "title":"Le spleen des petits",
              "creator":"Stupeflip",
              "annotation":"Pas pressés de l'amener à l'école...",
              "link":[
                 "https://www.youtube.com/watch?v=ODcGoug3dgg"
              ]
           },
           {
              "title":"Run From Me",
              "creator":"Timber Timbre",
              "link":[
                 "https://www.youtube.com/watch?v=rbity554pSg"
              ]
           },
           {
              "title":"L'eau vive",
              "creator":"Guy Béart",
              "link":[
                 "https://www.youtube.com/watch?v=ooUnyMwuBz0"
              ]
           },
           {
              "title":"Where Did you Sleep Last Night",
              "creator":"Leadbelly",
              "link":[
                 "https://www.youtube.com/watch?v=PsfcUZBMSSg"
              ]
           },
           {
              "title":"Who Be Lovin' Me (feat. ILOVEMAKONNEN)",
              "creator":"Santigold",
              "link":[
                 "https://www.youtube.com/watch?v=i0PD1nVz0kA"
              ]
           },
           {
              "title":"Run the World (Girls)",
              "creator":"Beyoncé",
              "annotation":"Parce que Qui dirige le monde?",
              "link":[
                 "https://www.youtube.com/watch?v=VBmMU_iwe6U"
              ]
           }
        ]
     }
      const response = {
        data:{
          playlist: jspf
        }
      }

      resolve(response);
    });

    //DEMO CONTENT - un peu de tout
    const promiseC = new Promise((resolve, reject) => {
      const jspf = {
      "title":"TEST PLAYLIST",
      "creator":null,
      "annotation":null,
      "info":null,
      "location":null,
      "identifier":null,
      "image":null,
      "date":null,
      "attribution":[

      ],
      "link":[

      ],
      "meta":[],
      "extension":[

      ],
      "track":[
        {
           "creator":"DROLEDENOM",
           "title":"PASDETITRENAN",
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",

           "identifier":[

           ],
           "location":[
             'https://www.caca.com/watch?v=K5LU8K7ZK34',
             'https://pipi.com/i_d_magazine/premiere-sonnymoon-grains-of-friends'
           ],
           "annotation":"This track should not be found."
        },
        {
           "creator":"Black Flower",
           "title":"Bones",
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "location":[

           ],
           "identifier":[
             'https://open.spotify.com/track/2UAj7KKkIELY7RcQnkbC48'
           ],
           "annotation":"HAS SPOTIFY ID"
        },
        {
           "creator":"Sonnymoon",
           "title":"Grains of Friends",
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "location":[
             'https://www.youtube.com/watch?v=K5LU8K7ZK34',
             'https://soundcloud.com/i_d_magazine/premiere-sonnymoon-grains-of-friends'
           ],
           "identifier":[

           ],
           "annotation":"HAS SOURCES"
        },
        {
           "creator":"Sonnymoon",
           "title":"Grains of Friends",
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "location":[
             'https://soundcloud.com/i_d_magazine/premiere-sonnymoon-grains-of-friends'
           ],
           "identifier":[

           ],
           "annotation":"1 DISABLED PROVIDER"
        },
        {
           "creator":"Sonnymoon",
           "title":"Grains of Friends",
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "location":[
             'https://www.youtube.com/watch?v=K5LU8K7ZaaaaaddddaaaK3XXXX',
             'https://www.youtube.com/watch?v=K5LU8K7ZaaaaaddddaaaK3YYYY',
             'https://www.youtube.com/watch?v=K5LU8K7ZaaaaaddddaaaK3ZZZZ',
             'https://soundcloud.com/i_d_magazine/premiere-sonnymoon-grains-of-friends'
           ],
           "identifier":[

           ],
           "annotation":"ONLY 1 PLAYABLE SOURCE"
        },
        {
           "creator":"Sonnymoon",
           "title":"Grains of Friends",
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "location":[
             'https://www.youtube.com/watch?v=K5LU8K7ZaaaaaddddaaaK34',
             'https://soundcloud.com/i_d_magazine/premiere-sonnymoon-grains-of-friends',
             'https://www.youtube.com/watch?v=K5LU8K7ZaaaaaddddfffaaaK34'
           ],
           "identifier":[

           ],
           "annotation":"HAS UNPLAYABLE SOURCES"
        },
        {
           "creator":"Pépite",
           "title":"Les Bateaux",
           "album":null,
           "trackNum":null,
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "duration":null,
           "location":[

           ],
           "identifier":[
             "https://open.spotify.com/track/7M8nUdtIbUSsIHqFqUUDcUZZZZZ",
           ],
           "annotation":"HAS NO SOURCES AND A WRONG SPOTIFY ID"
        },
        {
           "creator":"Pépite",
           "title":"Les Bateaux",
           "album":null,
           "trackNum":null,
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "duration":null,
           "location":[

           ],
           "identifier":[

           ],
           "annotation":"HAS NO ID NEITHER SOURCES"
        }
      ]
      }
      const response = {
        data:{
          playlist: jspf
        }

      }
      resolve(response);
    });

    //DEMO CONTENT - no playable tracks
    const promiseD = new Promise((resolve, reject) => {
      const jspf = {
      "title":"TEST PLAYLIST",
      "creator":null,
      "annotation":null,
      "info":null,
      "location":null,
      "identifier":null,
      "image":null,
      "date":null,
      "attribution":[

      ],
      "link":[

      ],
      "meta":[],
      "extension":[

      ],
      "track":[
        {
           "creator":"NOT PLAYABLE",
           "title":"NOT PLAYABLE A",
           "link":[],
           "location":[
             'https://www.caca.com'
           ],
           "identifier":[],
           "annotation":"HAS UNPLAYABLE SOURCES"
        },
        {
           "creator":"NOT PLAYABLE",
           "title":"NOT PLAYABLE B",
           "link":[],
           "location":[
             'https://www.caca.com'
           ],
           "identifier":[],
           "annotation":"HAS UNPLAYABLE SOURCES"
        },
        {
           "creator":"NOT PLAYABLE",
           "title":"NOT PLAYABLE C",
           "link":[],
           "location":[
             'https://www.caca.com'
           ],
           "identifier":[],
           "annotation":"HAS UNPLAYABLE SOURCES"
        },
        {
           "creator":"NOT PLAYABLE",
           "title":"NOT PLAYABLE D",
           "link":[],
           "location":[
             'https://www.caca.com'
           ],
           "identifier":[

           ],
           "annotation":"HAS UNPLAYABLE SOURCES"
        }
      ]
      }
      const response = {
        data:{
          playlist: jspf
        }

      }
      resolve(response);
    });

    //DEMO CONTENT - single track
    const promiseE = new Promise((resolve, reject) => {
      const jspf = {
        "title":"Codename O.",
        "creator":"gordie",
        "annotation":"test",
        "info":null,
        "track":[
           {
              "title":"Take It Easy - first is SC preview",
              "creator":"Hopeton Lewis",
              "identifier":[
                "https://musicbrainz.org/recording/4cdf1775-6834-415c-b384-ac3f19174124",
                "https://open.spotify.com/track/7M8nUdtIbUSsIHqFqUUDcU",
              ],
              "location":[
                "https://soundcloud.com/jimmy-bo-horne/you-get-me-hot",
                "https://soundcloud.com/hopeton-lewis/take-it-easy-3",
                 "https://www.youtube.com/watch?v=7pnuW8V8obc",
              ],
              "link":[
                "https://fr.wikipedia.org/wiki/Hopeton_Lewis",
                "https://www.discogs.com/fr/Hopeton-Lewis-Take-It-Easy/release/8119396"

              ]
           }
        ]
     }
      const response = {
        data:{
        playlist: jspf
      }
      }
      resolve(response);
    });

    //DEMO CONTENT - timeout skip
    const promiseF = new Promise((resolve, reject) => {
      const jspf = {
      "title":"TIMEOUT BUG",
      "creator":null,
      "annotation":null,
      "info":null,
      "location":null,
      "identifier":null,
      "image":null,
      "date":null,
      "attribution":[

      ],
      "link":[

      ],
      "meta":[],
      "extension":[

      ],
      "track":[
        {
           "creator":"Sonnymoon",
           "title":"Grains of Friends",
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "location":[
             'https://www.youtube.com/watch?v=K5LU8K7ZK34',
             'https://soundcloud.com/i_d_magazine/premiere-sonnymoon-grains-of-friends'
           ],
           "identifier":[

           ],
           "annotation":"This track will play."
        },
        {
           "creator":"Gorillaz",
           "title":"68 State",
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "location":[
             'https://www.youtube.com/watch?v=MkN4ROAwbzs'
           ],
           "identifier":[

           ],
           "annotation":"This one too."
        },
        {
           "creator":"Sonnymoon",
           "title":"Grains of Friends",
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "location":[
             'https://www.youtube.com/watch?v=K5LU8K7ZaaaaaddddaaaK34',
             'https://www.youtube.com/watch?v=K5LU8K7ZaaaaZZZaddddaaaK34',
           ],
           "identifier":[

           ],
           "annotation":"URLs for this tracks are not valid - track won't play."
        },
        {
           "creator":"Pépite",
           "title":"Les Bateaux",
           "album":null,
           "trackNum":null,
           "image":"https://lastfm.freetls.fastly.net/i/u/64s/2a96cbd8b46e442fc41c2b86b821562f.png",
           "duration":null,
           "location":[

           ],
           "identifier":[

           ],
           "annotation":"This track should grab sources from API and play."
        }
      ]
      }
      const response = {
        data:{
          playlist: jspf
        }

      }
      resolve(response);
    });

    return promise
    .then(resp => resp.data)
    .then(resp => new playlistModel(resp.playlist))
  }

  static async getServiceTrack(trackObj,service){

    if (!trackObj.creator || !trackObj.title){
      throw new Error("A track artist + title is required.");
    }

    //don't send the whole thing, just the useful data
    const reducedTrackObj = {
      creator:trackObj.creator,
      title:trackObj.title,
      album:trackObj.album
    }

    const config = {
      method: 'post',
      url: '/v2/track/search/',
      withCredentials:true,
      data: {
        track:reducedTrackObj,
        service:service
      }
    }

    DEBUG && console.debug(config);

    return importAPI.request(config)
    .then(resp => resp.data.track)
    .then(jspf => new trackModel(jspf))
  }

  static async getLinksForTrack(trackObj){

    const jspfTrack = {...trackObj};

    const config = {
     method: 'post',
     url: '/v2/track/links/',
     withCredentials:true,
     data: {
       track: jspfTrack
     }
    }

    DEBUG && console.debug(config);

    return importAPI.request(config)
    .then(function(res){
      DEBUG && console.debug('getLinksForTrack results',res);
      return res;
    })
  }

}

export default ImportAPI;
