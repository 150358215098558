import React, {useEffect,useState} from "react";
import { Input } from 'semantic-ui-react';
import classNames from "classnames";
import DelayedOnChangeComponent from "../../components/DelayedOnChangeComponent";
import playlistModel from  "../../playlist/Playlist.Model";

export const WizardTabUrl = (props) => {
  const [urlInputTimer, setUrlInputTimer] = useState(null);
  const [textInput, setTextInput] = useState('');

  //prop updated
  useEffect(() => {
    const url = props.playlist?.getMetaValue('import_url');
    console.log("URL UPPDATE",url);
    setTextInput(url);
  }, [props.playlist]);

  const handleClearRequest = (e) => {
    e.preventDefault();
    const playlist = new playlistModel();
    props.onChange(playlist);
  };

  const handleChange = (e) => {
    e.preventDefault();

    //pass to parent
    //(as a playlist rather than an URL so we might add options).
    const playlist = new playlistModel();
    playlist.setMetaValue('import_url',e.target.value);

    props.onChange(playlist);
  };

  return (
    <DelayedOnChangeComponent component={
      <Input
        action={{
          icon: 'delete',
          onClick:handleClearRequest
        }}
        fluid
        name="wizard_url"
        placeholder='Playlist URL'
        defaultValue={textInput}
        onChange={handleChange}
      />
    }
    />
  )
}
