import "./Playlist.scss";
import React, { useEffect,useState,useContext,useReducer,useRef } from "react";

import {
  useNavigate
} from "react-router-dom";

import { Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import classNames from "classnames";
import { saveAs } from 'file-saver';
import {DEBUG} from "../Constants";
import { useUser } from "../user/User.Context";
import DatabaseAPI from "../databaseAPI/api";
import trackModel from  "../track/Track.Model";
import playlistModel from  "./Playlist.Model";
import { Player } from "../player/Player";
import { PlaylistHeader } from "./Playlist-Header";
import Tracklist from "../tracklist/Tracklist";
import PlaylistEditModal from "./edit/PlaylistEditModal";
import { movePlaylistTrack } from "../track/actions";
import {getToastErrorMessage,isEqualPlaylists} from "../utils/Utils";
import {isEqualPlaylistComponent} from "../utils/EqualComponents";

function jspfReducer(state, action){
  DEBUG && console.log('JSPF PLAYLIST REDUCER:',action.type);
  switch(action.type) {
    case 'UPDATE_JSPF':
      return Object.assign(
        new playlistModel(),
        state,
        action.payload
      );
    break;
    case 'UPDATE_JSPF_TRACK':
      var [index,jspf] = action.payload;

      //update only our track.
      //https://stackoverflow.com/questions/35628774/how-to-update-single-value-inside-specific-array-item-in-redux
      return Object.assign(
        new playlistModel(),
        state,
        {
          track: state.track.map(
            (track, i) => i === index ? new trackModel(jspf) : track
          )
        },
      );

    break;
    case 'DELETE_TRACK':
      //TOUFIX RE-INDEX TRACKS ?
      var index = action.payload;
      return Object.assign(
        new playlistModel(),
        state,
        {
          track: state.track.filter(
            (track, i) => i === index ? false : true
          )
        },
      );
    break;
    case 'ADD_JSPF_TRACK':
      return Object.assign(
        new playlistModel(),
        state,
        {
          track:state.track.concat([new trackModel(action.payload)])
        }
      );
    break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const Playlist = (props) => {

  const [playlist, dispatch] = useReducer(jspfReducer,props.playlist);

  const userContext = useUser();
  let navigate = useNavigate();
  const oldPlaylistRef = useRef();
  const [hasEdits, setHasEdits] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const [isUpdating,setIsUpdating] = useState(false);
  const [isFavoriting,setIsFavoriting] = useState(false);
  const isFavorited = userContext.filterIsFavoritePost(playlist);

  //capabilities
  const canSavePost = userContext.canSavePost(playlist);
  const canFavoritePost = userContext.user.profile?.capabilities.edit_posts;
  const canEditTracks = true;
  const canAddTracks = (!playlist.isLive() && canSavePost);
  const canQueueTracks = userContext.user.profile?.capabilities.edit_posts;
  const canFavoriteTracks = userContext.user.profile?.capabilities.edit_posts;

  //when prop is updated, update state.
  useEffect(()=>{
    updateJspf(props.playlist);
  },[props.playlist]);

  const updateJspf = playlist => {
    const jspf = {...playlist};
    console.log("UPDATE JSPF",jspf);
    dispatch({ type: 'UPDATE_JSPF', payload: jspf });
  }

  const handleDeleteTrack = index => {
    dispatch({ type: 'DELETE_TRACK', payload:index});
  }

  const handleUpdateTrack = (index,track) =>{
    dispatch({ type: 'UPDATE_JSPF_TRACK', payload: [index,{...track}] });

    //URGENT TOUFIX TOUCHECK!!!
    /*
    if (prevJspf){
      //move track ?
      const prevIndex = parseInt(prevJspf.trackNum) - 1;
      const newIndex = parseInt(jspf.trackNum) - 1;
      if (newIndex !== prevIndex){
        newPlaylistJspf = movePlaylistTrack(playlist,prevIndex,newIndex);
        //eventually switch indices

        /*
        //TOUFIX NOT WORKING
        const playlisterIndices = playlisterPlaylist.getCurrentIndices();
        if (prevIndex === playlisterIndices[0]){
          newIndices = newIndex;
          console.log("THE CURRENT TRACK POSITION HAS BEEN UPDATED, UPDATE PLAYLISTER INDICES",newIndices);
          setIndices(newIndices);
        }
        */

        /*
      }
    }
    */

  }

  const handleAddTrack = track => {
    dispatch({ type: 'ADD_JSPF_TRACK', payload: track });
  }

  const handleSavePost = async(e) => {

    const addPost = async(playlist) => {
      setIsUpdating(true);

      return userContext.addPost(playlist)
      .then((newPlaylist) => {
        const url = newPlaylist.getPostUrl();
        navigate(url);
      })
      .catch(error => {
        toast.error(getToastErrorMessage(error,'Error while creating post'));
      })
      .finally(function(){
        setIsUpdating(false);
      })
    }
    const updatePost = async(playlist) => {
      setIsUpdating(true);

      return userContext.updatePost(playlist)
      .then((newPlaylist) => {
        updateJspf(newPlaylist);
        setHasEdits(false);
        toast.success('Post successfully updated.');
      })
      .catch(error => {
        toast.error(getToastErrorMessage(error,'Error while updating post'));
      })
      .finally(function(){
        setIsUpdating(false);
      })
    }

    const post_id = playlist.getMetaValue('post_id');

    if(post_id){
      return updatePost(playlist);
    }else{
      return addPost(playlist);
    }

  }

  const handleDeletePost = async() => {
    setIsUpdating(true);
    return userContext.deletePost(playlist)
    .then((newPlaylist) => {
      setHasEdits(false);
      toast.success('Post successfully deleted.');
    })
    .catch(error => {
      toast.error(getToastErrorMessage(error,'Error while deleting post'));
    })
    .finally(function(){
      setIsUpdating(false);
    })
  }

  const toggleFavoritePost = async bool => {
    let promise;
    setIsFavoriting(true);

    return userContext.toggleFavoritePost(playlist,bool)
    .then(function(favPostIDs){
      const message = bool ? 'Post successfully favorited.' : 'Post successfully unfavorited.';
      toast.success(message);
    })
    .catch(error => {
      toast.error(getToastErrorMessage(error,'Error while toggling status'));
    })
    .finally(function(){
      setIsFavoriting(false);
    })

  }

  const [indices,setIndices] = useState(props.indices);
  const [playing,setPlaying] = useState(props.playing);
  const [playlisterControls,setPlaylisterControls] = useState();
  const [playlisterPlaylist,setPlaylisterPlaylist] = useState();

  const [isEditModal,setIsEditModal] = useState( (props.action === 'edit') );
  const [scrollToTrackIndex,setScrollToTrackIndex] = useState(undefined);

  const [filteredPlaylist,setFilteredPlaylist] = useState(undefined);

  //checks wheter the input JSPF has changed
  useEffect(()=>{

    let a = Object.assign(new playlistModel(),playlist);
    let b = Object.assign(new playlistModel(),oldPlaylistRef.current);

    if( !isEqualPlaylists(b,a) ){
      //increment load count
      setUpdateCount(updateCount+1);
    }

  },[playlist])


  useEffect(() => {

    if (updateCount===0) return;

    //how many times the playlist has been updated ?
    if (typeof props.onUpdate === 'function') {
      props.onUpdate(playlist,updateCount);
    }

    //should we enable the save icon ?
    const hasPlaylistEdits = () => {
      let a = Object.assign(new playlistModel(),playlist);
      let b = Object.assign(new playlistModel(),oldPlaylistRef.current);

      if ( a.isLive() ){
        a = a.toReducedLivePost();
        b = b.toReducedLivePost();
      }else{
        a = a.toReducedPost();
        b = b.toReducedPost();
      }
      return !isEqualPlaylists(b,a);
    }

    setHasEdits( hasPlaylistEdits() );

    //keep a reference for later
    oldPlaylistRef.current = Object.assign(
      new playlistModel(),
      playlist
    );


  }, [updateCount]);

  useEffect(() => {
    setPlaying(props.playing);
  },[props.playing])

  //update indices if prop changes
  useEffect(() => {
    if (props.indices === undefined) return;
    setIndices(props.indices);
  }, [props.indices]);

  //if the tracks are filtered, update ReactPlaylister indices
  useEffect(() => {

    if (!playlisterPlaylist) return;

    const tracks = (filteredPlaylist !== undefined) ? filteredPlaylist.track : playlist.track;

    const getFilteredIndices = (tracks,oldIndices) => {

      if (oldIndices === undefined) return;

      const oldTrackIndex = oldIndices[0];
      const oldSourceIndex = oldIndices[1];
      const oldTrackNum = oldTrackIndex+1;

      let newTrackIndex = tracks.findIndex(track => {
        return (track.trackNum === oldTrackNum)
      })

      newTrackIndex = (newTrackIndex === -1) ? undefined : newTrackIndex;
      if (newTrackIndex===undefined) return;

      return [newTrackIndex,oldSourceIndex]
    }

    const oldIndices = playlisterPlaylist.getCurrentIndices();
    const newIndices = getFilteredIndices(tracks,oldIndices);
    const updated = ( JSON.stringify(newIndices) !== JSON.stringify(oldIndices));

    if (!updated) return;
    if (newIndices === undefined) return;

    console.log("!!!UPDATE OLD INDICES ("+JSON.stringify(oldIndices)+") FOR FILTERED TRACKS ("+JSON.stringify(newIndices)+")");
    setIndices(newIndices);

  },[filteredPlaylist])

  const handleExport = () => {
    console.log("EXPORT JSPF");

    const file_content = JSON.stringify({...playlist});
    //const file_type = 'application/xspf+xml;charset=utf-8';
    const file_type = 'application/jspf+json;charset=utf-8';
    const file_name = `export-${Date.now()}.jspf`;

    var blob = new Blob([file_content],
      {
        type: file_type
      }
    );
    saveAs(blob,file_name);

  }


  const handleTogglePlay = () => {
    setPlaying(!playing);
  }

  const handlePlaylistUpdated = (playlist) => {
    DEBUG && console.log("PLAYLISTER / PLAYLIST UPDATED",playlist);
    setPlaylisterPlaylist(playlist);
  }

  const handleControlsUpdated = (controls) => {
    DEBUG && console.log("PLAYLISTER / CONTROLS UPDATED",controls);
    setPlaylisterControls(controls);
  }

  const handleScrollToTrack = () => {
    const playlisterIndices = playlisterPlaylist.getCurrentIndices();
    setScrollToTrackIndex(playlisterIndices[0]);
  }

  const handleSetTrack = (trackIndex,sourceIndex) => {
    setIndices([trackIndex,sourceIndex]);
    setPlaying(true);
  }

  const handleFilterTracks = (filteredTracks) => {

    if (filteredTracks !== undefined){
      console.log("PLAYLIST FILTERED TO "+filteredTracks?.length+" TRACKS");

      const newPlaylist = {
        ...playlist,
        track:filteredTracks
      }
      setFilteredPlaylist(newPlaylist);
    }else{
      setFilteredPlaylist(undefined);
    }

  }

  const handleReload = () => {
    if (typeof props.onReload === 'function') {
      props.onReload();
    }
  }

  return (
    <div
    className={props.className ?  props.className+ ' ' : ''
    + classNames({
      playlist: true,
      radio:playlist.isLive()
    })}
    itemScope
    itemType="http://schema.org/MusicPlaylist"
    >
      {
        (updateCount === 0) ?
        <Loader active inline='centered' />
        :
        <>
          {isEditModal &&
            <PlaylistEditModal
            playlist={playlist}
            open={true}
            onClose={()=>{setIsEditModal(false)}}
            onSubmit={updateJspf}
            />
          }
          <PlaylistHeader
          playlist={playlist}
          canSave={canSavePost}
          hasEdits={hasEdits}
          updating={isUpdating}
          canFavorite={canFavoritePost}
          toggleFavorite={toggleFavoritePost}
          favorited={isFavorited}
          favoriting={isFavoriting}
          playlisterControls={playlisterControls}
          onTogglePlay={handleTogglePlay}
          onEdit={()=>{setIsEditModal(true)}}
          onSave={handleSavePost}
          onDelete={handleDeletePost}
          onReload={handleReload}
          onExport={handleExport}
          />
          <Player
          tracks={filteredPlaylist ?  filteredPlaylist.track : playlist.track}
          playing={playing}
          onTogglePlay={handleTogglePlay}
          onPlaylistEnded={props.onPlaylistEnded}
          onPlaylistUpdated={handlePlaylistUpdated}
          onControlsUpdated={handleControlsUpdated}
          onScrollToTrack={handleScrollToTrack}
          playlisterPlaylist={playlisterPlaylist}
          playlisterControls={playlisterControls}
          indices={indices}
          />
          <Tracklist
          key="tracklist"
          tracks={playlist.track}
          canAddTracks={canAddTracks}
          canEditTracks={canEditTracks}
          canQueueTracks={canQueueTracks}
          canFavoriteTracks={canFavoriteTracks}
          scrollTo={scrollToTrackIndex}
          playlisterPlaylist={playlisterPlaylist}
          playing={playlisterControls?.playing}
          loading={playlisterControls?.loading}
          onTogglePlay={handleTogglePlay}
          onSetTrack={handleSetTrack}
          onFilterTracks={handleFilterTracks}
          emptyTracklist={props.emptyTracklist}
          onAddTrack={handleAddTrack}
          onUpdateTrack={handleUpdateTrack}
          onDeleteTrack={handleDeleteTrack}
          />
        </>
      }
    </div>
  )

}

//export default React.memo(Playlist,isEqualPlaylistComponent);
export default Playlist;
