import React,{useState} from "react";
const DelayedOnChangeComponent = (props) => {

  const [timer, setTimer] = useState(null);
  let component = props.component;
  const initialFn = component?.props?.onChange;
  const delay = props.delay || 500;

  //wait user stops typing
  const handleChange = e => {
    e.persist();
    clearTimeout(timer)

    if (initialFn === undefined) return;

    const newTimer = setTimeout(() => {
      initialFn(e);
    }, delay)

    setTimer(newTimer);

  }

  //override component prop with our fn
  component = {
    ...component,
    props:{
      ...component?.props,
      onChange:handleChange
    }
  };

  return (
    <>
      {component}
    </>
  )
}

export default DelayedOnChangeComponent;
