import React from "react";
import { Menu,Label } from 'semantic-ui-react';
import { useUser } from "../user/User.Context";

import {isRoutePosts,isRouteFavoritePosts,getRouteUsername,getRoutePlaylistType} from "../utils/Utils";
import { LastLocationPath } from "../utils/LastLocationPath";
import {DEFAULT_PLAYLIST_LINK} from "../Constants";
import {useNavigate,useLocation,matchRoutes,Link} from "react-router-dom";

const MainMenu = (props) => {

  let navigate = useNavigate();
  let location = useLocation();
  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;

  const routeUsername = getRouteUsername(location);
  const routePlaylistType = getRoutePlaylistType(location);
  const routeFavorited = isRouteFavoritePosts(location);
  const routePosts = isRoutePosts(location);
  const routeFavoritedPosts = isRouteFavoritePosts(location);

  const lastLocationPath = LastLocationPath.get();
  const playingMenuUrl = lastLocationPath ? lastLocationPath : DEFAULT_PLAYLIST_LINK;


  const getLocationParams = () => {
    const routesMatch = matchRoutes([
      {path:'/music/:username'},
      {path:'/music/:username/:page'},
      {path:'/music/:username/:page/:subpage'},
    ], location);

    const match = routesMatch ? routesMatch[0] : undefined;
    return match?.params;
  }

  const locationParams = getLocationParams();

  const followedUserIds = (userContext.user.profile?.users_followed || []).map(function(user){
    return user.ID;
  })

  const userPlaylistsLink = myUsername ? `/music/${myUsername}/playlists` : '#';
  const userFavoritedTracksLink = myUsername ? `/music/${myUsername}/tracks` : '#';
  const userFriendsPlaylistsLink = ( myUsername && followedUserIds.length ) ? `/music/friends/playlists` : '#';

  return (
    <Menu vertical id='left-menu'>
    <Menu.Item>
      <Menu.Menu>
        <Link to="/">
          <Menu.Item
            name='About'
            active={( location.pathname === '/' )}
          />
        </Link>
        {
          /*
          TOUFIX IS THIS USEFUL ?J
          <Link to={playingMenuUrl}>
            <Menu.Item
              name='Playing'
              active={location.pathname.startsWith("/playlist/")}
            />
          </Link>
          */
        }
        <Link to={`/music/community/playlists`}>
          <Menu.Item
            name='All Playlists'
            active={( (routeUsername==='community') && routePosts )}
          />
        </Link>
        <Link to="/wizard/url">
          <Menu.Item
            active={location.pathname.startsWith("/wizard")}
            >
            Search / Import
          </Menu.Item>
        </Link>
      </Menu.Menu>
    </Menu.Item>
    <Menu.Item>
      <span className="menu-title handwritten">Staff picks</span>
      <Menu.Menu>
        <Link to="/music/staff/tracks">
          <Menu.Item
            name='Tracks'
            active={( (routeUsername==='staff') && (locationParams?.page==='tracks') )}
          />
        </Link>
        <Link to="/music/staff/playlists/favorited">
          <Menu.Item
            name='Playlists'
            active={( routePosts && (routeUsername==='staff') )}
          />
        </Link>
      </Menu.Menu>
    </Menu.Item>
    <Menu.Item  disabled={!userContext.isLogged}>
      <span className="menu-title handwritten">Me</span>
      <Menu.Menu>
        <Link to={userPlaylistsLink} disabled={!userContext.isLogged}>
          <Menu.Item
            name='Playlists'
            active={( (routeUsername===myUsername) && routePosts && !routeFavoritedPosts )}
          />
        </Link>
        <Link to={userFavoritedTracksLink} disabled={!userContext.isLogged}>
          <Menu.Item
            name='Tracks'
            active={( (routeUsername===myUsername) && (locationParams?.page==='tracks') )}
          />
        </Link>
      </Menu.Menu>
    </Menu.Item>
    <Menu.Item disabled={!userContext.isLogged}>
      <span className="menu-title handwritten">Friends</span>
      {
        userContext.isLogged &&
        <Label>{followedUserIds.length}</Label>
      }
      <Menu.Menu>
        <Link to={userFriendsPlaylistsLink} disabled={!followedUserIds.length}>
          <Menu.Item
            name='Playlists'
            active={( (routeUsername==='friends') && routePosts && !routeFavoritedPosts )}
          />
        </Link>
      </Menu.Menu>
    </Menu.Item>
  </Menu>
  )
}

export default MainMenu;
