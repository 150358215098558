import {
  filterIsSinglePropObj,
  setJspfObjectValueInArrayByKey,
  filterIdentifierUrls
} from "../utils/Utils";
import {
  filterPlayableUrls
} from "../player/utils";

import {DEBUG,SPOTIFY_TRACK_ID_REGEX,MUSICBRAINZ_TRACK_ID_REGEX} from "../Constants";

import md5 from 'md5';
import _ from 'lodash';

export default class trackModel {

  constructor(jspf){

    //those are the regular JSPF props.
    //use nothing else than that.

    this.location=     [];
    this.identifier=   [];
    this.title=        null;
    this.creator=      null;
    this.annotation=   null;
    this.info=         null;
    this.image=        null;
    this.album=        null;
    this.trackNum=     null;
    this.duration=     null;
    this.link=         [];
    this.meta=         [];
    this.extension=    [];

    if (jspf){

      //unset value if it is not an array type
      const needArrayProps = ['location','identifier','link','meta','extension'];
      needArrayProps.forEach(function(prop){
        if ( jspf[prop] && !Array.isArray(jspf[prop]) ){
          delete jspf[prop];
        }
      });

      //arrays of single-prop objects
      jspf.link = (jspf.link || []).filter(filterIsSinglePropObj);
      jspf.meta = (jspf.meta || []).filter(filterIsSinglePropObj);
      jspf.extension = (jspf.extension || []).filter(filterIsSinglePropObj);


      //ignore properties in input object that should not exists
      for (const [prop, value] of Object.entries(jspf)) {
        if ( !this.hasOwnProperty(prop) ) continue;
        const initial = this[prop];
        this[prop] = value || initial;
      }

    }

    //we cannot be sure which URLs are playable on track init,
    //because maybe that ReactPlayer can play some URLs that are not set on the location property.
    this.location = filterPlayableUrls( this.getAllUrls() );

  }

  //extract URLS from track links
  getLinkUrls(){
    return (this.link || []).map(function(link){
      return Object.values(link)[0];
    });
  }

  //get links filtered with a key prefix
  getLinksByKeyPrefix(prefix){
    return (this.link || []).filter(function(link){
      const prop = Object.keys(link)[0];
      return prop.startsWith(prefix);
    })
  }

  getMetas(){

    let output = {};

    this.meta.forEach(function(jspfMeta) {
      const key = Object.keys(jspfMeta)[0];
      const value = Object.values(jspfMeta)[0];
      output[key] = value;
    });

    return output;

  }

  getMetaValue(key){
    return this.getMetas()[key];
  }

  //set meta value by key
  setMetaValue(key,value){
    return setJspfObjectValueInArrayByKey(this.meta,key,value);
  }

  deleteMetaValue(key){
    //remove private metas
    this.meta = this.meta.filter(function(meta){
      const prop = Object.keys(meta)[0];
      return prop !== key;
    })
  }

  filterReducedMetas(){
    //remove private metas
    this.meta = this.meta.filter(function(meta){
      const prop = Object.keys(meta)[0];
      return !prop.startsWith("private/");
    })
  }

  getSpotifyId(){
    let id = undefined;
    this.identifier.forEach(function(url){
      const matches = url.match(SPOTIFY_TRACK_ID_REGEX);
      if (!matches) return;
      id = matches[1] ?? undefined;
    })
    return id;
  }

  getMusicbrainzId(){
    let id = undefined;
    this.identifier.forEach(function(url){
      const matches = url.match(MUSICBRAINZ_TRACK_ID_REGEX);
      if (!matches) return;
      id = matches[1] ?? undefined;
    })
    return id;
  }

  get hash(){
    return md5(this.creator+'|'+this.title);
  }

  isScrobblable(){
    if ( !this.creator ) throw new Error('Missing track artist.');
    if ( !this.title ) throw new Error('Missing track title.');
    return true;
  }

  //return a new object with only the properties that does not equals the defaults.
  //used for wizard URLs.
  toReducedPost(){

    //clone
    let reduced = Object.assign(
      new trackModel(),
      this
    );

    //remove private metas
    reduced.filterReducedMetas();

    const defaults = {...new trackModel()};


    for (const prop of Object.keys(defaults)) {

      const a = defaults[prop];
      const b = reduced[prop];

      if (_.isEqual(a,b)){
        delete reduced[prop];
      }

    }

    return reduced;
  }

  addLink(url,prefix){

    const existing = this.getLinksByKeyPrefix(prefix);//links existing with this prefix
    const linkIndex = existing.length+1;
    prefix = prefix + '/' + linkIndex;
    let link = {};
    link[prefix] = url;

    this.link.push(link);
  }

  removeLink(url){
    this.link = (this.link || []).filter(function(link){
      const prop = Object.keys(link)[1];//link url
      return prop !== url;
    })
  }

  //return all (unique) URLs (identifiers,locations,links) from a track
  getAllUrls(){
    let urls = [];
    var identifier_urls = this.identifier || [];
    const location_urls = this.location || [];
    const link_urls = this.getLinkUrls();

    urls = urls.concat(identifier_urls,location_urls,link_urls);
    return [...new Set(urls)];//make unique
  }

  //add non-sorted URLs to the track; either to identifier, location, or link.
  addUrls(urls,prefix){

    const track = this;

    //filter urls
    let new_location_urls = filterPlayableUrls(urls);
    let new_identifier_urls = filterIdentifierUrls(urls);
    let new_link_urls = urls.filter(url => (!new_location_urls.includes(url) && !new_identifier_urls.includes(url)) );

    //ignore already populated URLs
    new_location_urls = new_location_urls.filter(url => !track.location.includes(url) );
    new_identifier_urls = new_identifier_urls.filter(url => !track.identifier.includes(url) );
    new_link_urls = new_link_urls.filter(url => !track.getLinkUrls().includes(url) );

    const new_urls = [...new_location_urls,...new_identifier_urls,...new_link_urls];

    //append
    track.location = [...track.location,...new_location_urls];
    track.identifier = [...track.identifier,...new_identifier_urls];

    new_link_urls.forEach(function(url) {
      track.addLink(url,prefix);
    })

  }

  removeUrls(urls){
    const track = this;
    track.location = track.location.filter(x => !urls.includes(x));
    track.identifier = track.identifier.filter(x => !urls.includes(x));

    urls.forEach(function(url) {
      track.removeLink(url);
    })

  }

}
