/*index.jsx*/
import React, {useEffect,useState} from "react";
import {
  useNavigate,
  useLocation,
  matchRoutes
} from "react-router-dom";
import { Form,Button,Checkbox,Container,Message,Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import {LASTFM_API,DEBUG} from "../../../Constants";
import { getToastErrorMessage } from "../../../utils/Utils";

import LastFmAPI from "../../../importAPI/lastfm";
import { useUser } from "../../../user/User.Context";
import classNames from "classnames";

const LastfmSettingsSection = (props) => {
  let navigate = useNavigate();
  const location = useLocation();


  const userContext = useUser();
  const userProfile = userContext.user.profile;
  const data = userProfile?.lastfm;
  const [newData,setNewData] = useState(data);


  const lastFmSessionKey = userContext.user.profile?.lastfm?.session_key;
  const [lastFmProfile,setLastFmProfile] = useState();
  const [isLoading,setIsLoading] = useState(false);
  const [isCheckingApi,setIsCheckingApi] = useState(false);

  const handleSetLastfmScrobblerEnabled = (event) => {
    setNewData({
      ...newData,
      scrobbler:!newData.scrobbler
    })
  }

  const handleSetLastfmSyncFavoritesEnabled = (event) => {
    setNewData({
      ...newData,
      sync_favorites:!newData.sync_favorites
    })
  }

  //check for callback URL token and get user session key
  useEffect(()=>{

    //check if this is the LastFM API callback
    const getUrlToken = () => {

      const routeMatch = matchRoutes([{path:'/user/:username/settings/lastfm'}], location);
      if (!routeMatch) return;

      //get lastFm token
      const urlParams = new URLSearchParams(location.search);
      return urlParams.get('token');
    }

    const token = getUrlToken();
    if (!token) return;


    LastFmAPI.getSessionKey(token)
    .then(function(resp){
      return updateData({
        ...newData,
        username:resp.name,
        session_key:resp.key
      });
    })
    .then(function(newData){
      //change URL so token is not checked a second time (would return an error from Last.fm )
      const successUrl = props.location.pathname;
      navigate(successUrl);
    })
    .catch((error) => {
      toast.error('We have not been able to get your Last.fm session token.');
      //TOUFIX TODO disconnect last.fm ?
    });

  },[location])

  //make sure user is connected by testing the Last.fm API
  useEffect(()=>{
    if (!lastFmSessionKey) return;

    setIsCheckingApi(true);
    LastFmAPI.getUserInfo(lastFmSessionKey)
    .then(function(resp){
      setLastFmProfile(resp);
    })
    .catch(function(error){
      toast.error('Error getting your Last.fm profile data.');
    })
    .finally(function(){
      setIsCheckingApi(false);
    })

  },[lastFmSessionKey])

  const updateData = async(data) => {
    setIsLoading(true);
    return userContext.updateProfile({lastfm:data})
    .then(resp => resp.lastfm)
    .then(function(lastfm){
      setNewData(lastfm);
      toast.success("Last.fm settings successfully updated.");
      return lastfm;
    })
    .catch(function(error){
      toast.error(getToastErrorMessage(error,'Error updating Last.fm settings'));
    })
    .finally(function(){
      setIsLoading(false);
    })
  }

  const handleSubmitForm = async() => {
    updateData(newData);
  }

  const handleDisconnect = async(e) => {
    e.preventDefault();
    setLastFmProfile();
    updateData({});
  }

  const LastFmMessage = () => {
    const sessionUrl = 'http://www.last.fm/api/auth/?api_key='+LASTFM_API;
    const sessionLink = () => {
      return (<a href={sessionUrl} target="_blank" rel="noopener noreferrer">authorize our app</a>);
    }

    return(
      <Message
      icon
      className={classNames({
        flash:isCheckingApi,
        freeze:isCheckingApi
      })}
      >
        {
          isCheckingApi ?
            <Icon name='circle notch' loading />
          :
            <Icon name='lastfm' />
        }

        {
          !lastFmProfile ?
          <Message.Content>
            <Message.Header>Connect your Last.fm account !</Message.Header>
            <p>We can scrobble the tracks you listen to on your last.fm account, and much more!</p>
            <p>Please first {sessionLink()}, then paste here above the <em>token</em> value from the URL your are redirected at.</p>
          </Message.Content>
          :
          <Message.Content>
            <Message.Header>Connected to Last.fm as <em>{lastFmProfile?.name}</em></Message.Header>
          </Message.Content>
        }
      </Message>
    )
  }

  return (

    <Container id="settings-lastfm">
    <h3>Last.fm</h3>
    <LastFmMessage/>
      <Form
      onSubmit={handleSubmitForm}
      className={classNames({
        'loading': isLoading,
        'freeze': !lastFmSessionKey
      })}
      >
        <Form.Field>
          <Checkbox
          toggle
          label='Enable scrobbler'
          defaultChecked={data?.scrobbler}
          onChange={handleSetLastfmScrobblerEnabled}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
          toggle
          label='Sync with Last.fm when favoriting or unfavoriting a track'
          defaultChecked={data?.sync_favorites}
          onChange={handleSetLastfmSyncFavoritesEnabled}
          />
        </Form.Field>
        <Button type='submit'>Save</Button>
        <Button
        type="button"
        onClick={handleDisconnect}
        disabled={!lastFmSessionKey}
        >Disconnect</Button>
      </Form>
    </Container>
  );
};

export default LastfmSettingsSection;
