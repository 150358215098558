import React, { useState, useEffect,useContext } from "react";
import { toast } from 'react-toastify';
import LastFmAPI from "../importAPI/lastfm";
import { PlayerControls } from "./PlayerControls";
import { ReactPlaylister } from "../components/ReactPlaylister/index.js";
import "./Player.scss";
import classNames from "classnames";

import {PLAYER_ENABLED} from "../Constants";

import { useUser } from "../user/User.Context";
import { PlayerContext } from "../player/Player.Context";

import trackModel from "../track/Track.Model";

import { DatabaseApiCache } from "../databaseAPI/cache";
import { filterSortedProviderKeys,filterDisabledProviderKeys,getJspfMetaValue,getToastErrorMessage} from "../utils/Utils";

//hooks: https://github.com/CookPete/react-player/issues/844
export const Player = (props) => {

  const userContext = useUser();
  const lastfmSessionKey = userContext.user.profile?.lastfm.session_key;
  const lastfmScrobbler = userContext.user.profile?.lastfm.scrobbler;


  const [playerState, playerDispatch] = useContext(PlayerContext);

  const playlisterRef = React.createRef();
  const playlisterControls = props.playlisterControls;
  const playlisterPlaylist = props.playlisterPlaylist;

  const [playlisterUrls,setplaylisterUrls] = useState(undefined);

  //TOUFIX URGENT
  //this is a quick fix to get the user's providers.  But it would be better to have them from the user's context.
  const userProviders = DatabaseApiCache.getLocalUser()?.providers;
  const sortedProviderKeys = filterSortedProviderKeys(userProviders?.order);
  const disabledProviderKeys = filterDisabledProviderKeys(userProviders?.disabled);

  //build player tracks based on playlist JSPF
  useEffect(() => {
    if (props.tracks === undefined) return;

    const urls = props.tracks.map(
      (track, i) => {
        return track.location;
      }
    );

    //update only if URLs set has changed
    //!!! Commented because what if didApiLinkRequest has changed, for instance?
    //if ( JSON.stringify(urls) === JSON.stringify(playlisterUrls) ) return;

    setplaylisterUrls(urls);

  }, [props.tracks]);

  //our tracks don't always have sources; and so will be recognized as not playable by ReactPlaylister; and thus skipped.
  //Set a track as playable until we've queried its links.
  const handleFilterPlayableTrack = (playable,playlisterTrack) => {
    const index = playlisterTrack.index;
    const track = props.tracks[index];
    const didApiLinkRequest = track.getMetaValue('private/linksRequested') ? true : false;
    return didApiLinkRequest ? playable : true;
  }

  //track as been set as playable using handleFilterPlayableTrack(), but is now requested and has no sources !
  //tell ReactPlaylister not to skip it:
  //we'll query the track links within the Track component; which will update the ReactPlaylister URLs.
  const handleFilterSkipUnsourcedTrack = (skip,playlisterTrack) => {
    const index = playlisterTrack.index;
    const track = props.tracks[index];
    const didApiLinkRequest = track.getMetaValue('private/linksRequested') ? true : false;
    return didApiLinkRequest ? skip : false;
  }

  const handleSourceEnded = source => {

    //get playlist track
    const track = props.tracks[source.trackIndex];

    //scrobble track to Last.fm
    if ( lastfmScrobbler && lastfmSessionKey ){
      try{

        track.isScrobblable();//this could throw an error

        //we need a duration to scrobble a track.
        const newTrackObj = new trackModel({
         ...track,
         duration:source.duration
        })

        userContext.setLastfmLoading(true);
        LastFmAPI.scrobbleTrack(newTrackObj,lastfmSessionKey)
        .catch(function(error){
          toast.error(getToastErrorMessage(error,'Error scrobbling track'));
        })
        .finally(function(){
    	    userContext.setLastfmLoading(false);
    	  });

      } catch (error) {
      }
    }



  }

  const handleOnProgress = (state) => {

    playerDispatch({
      type: "SET_MEDIA_PROGRESS",
      payload:state.played
    });

  }

  return (
    PLAYER_ENABLED &&
    <div className="player">
      <div
      className={classNames({
        'embed-player':  true,
        'active':       playerState.widget
      })}
      >
        {
          <ReactPlaylister

          ref={playlisterRef}

          //props
          urls={playlisterUrls}
          index={props.indices} //track index OR [track index,source index]
          loop={playerState.looping}

          disabledProviders={disabledProviderKeys}
          sortedProviders={sortedProviderKeys}
          ignoreEmptyUrls={false}

          //ReactPlayer props
          playing={props.playing}
          volume={playerState.volume}
          muted={playerState.muted}
          controls

          //Callback props
          onPlaylistUpdated={props.onPlaylistUpdated}
          onControlsUpdated={props.onControlsUpdated}
          onSourceEnded={handleSourceEnded}
          onPlaylistEnded={props.onPlaylistEnded}
          filterPlayableTrack={handleFilterPlayableTrack}
          filterAutoplayableTrack={handleFilterPlayableTrack}
          filterSkipUnsourcedTrack={handleFilterSkipUnsourcedTrack}


          //ReactPlayer callback props
          //onReady={handleOnReady}
          //onStart={handleOnStart}
          onProgress={handleOnProgress}
          //onDuration={handleOnDuration}
          //onEnded={handleOnEnded}
          //onError={handleOnError}
          />
        }

      </div>
      <PlayerControls
      playlister={playlisterRef}
      playlisterPlaylist={playlisterPlaylist}
      playlisterControls={playlisterControls}
      onTogglePlay={props.onTogglePlay}
      onScrollToTrack={props.onScrollToTrack}
      />
    </div>
  )
}
