import React, { useState,useEffect } from "react";
import { Input,Dropdown } from 'semantic-ui-react'
import DelayedOnChangeComponent from "../../components/DelayedOnChangeComponent";
import playlistModel from  "../../playlist/Playlist.Model";

export const WizardTabArtist = (props) => {

  const [wizardUrl, setWizardUrl] = useState(props?.playlist?.getMetaValue('import_url'));
  const [artist, setArtist] = useState('');
  const [type, setType] = useState('lastfm-artist-top-tracks');
  const [album, setAlbum] = useState('');

  const artistOptions = [
    {
      key: 'album',
      text: 'Album',
      value: 'album'
    },
    {
      key: 'lastfm-artist-top-tracks',
      text: 'Top tracks',
      value: 'lastfm-artist-top-tracks'
    },
    {
      key: 'lastfm-similar-artist',
      text: 'Radio',
      value: 'lastfm-similar-artist'
    }
  ];

  //artist input changed
  useEffect(() => {
    const newUrl = buildPlaylistUrl(artist,type,album);
    setWizardUrl(newUrl);
  }, [artist]);

  //type changed
  useEffect(() => {
    const newUrl = buildPlaylistUrl(artist,type,album);
    setWizardUrl(newUrl);
  }, [type]);

  //album input changed
  useEffect(() => {
    const newUrl = buildPlaylistUrl(artist,type,album);
    setWizardUrl(newUrl);
  }, [album]);

  const buildPlaylistUrl = (artist,type,album) => {

    let url = '';

    switch(type){
      case 'lastfm-artist-top-tracks':
        if (artist){
          url = 'artist:' + artist;
        }
      break;
      case 'lastfm-similar-artist':
        if (artist){
          url = 'artist:' + artist + ':similar';
        }
      break;
      case 'album':
        if (artist && album){
          url = 'artist:' + artist + ':album:' + album;
        }
      break;
    }

    return url;

  }

  //send changes to parent
  useEffect(() => {

    //pass to parent
    //(as a playlist rather than an URL so we might add options).
    const playlist = new playlistModel();
    playlist.setMetaValue('import_url',wizardUrl);

    props.onChange(playlist);

  }, [wizardUrl]);

  return (
    <div>
      <DelayedOnChangeComponent component={
        <Input
          icon='user'
          iconPosition='left'
          placeholder='Artist'
          defaultValue={artist}
          onChange={event => setArtist(event.target.value)}
        />
      }/>
      <Dropdown
        placeholder='Options'
        defaultValue={type}
        options={artistOptions}
        onChange={(e, { value }) =>{
          setType(value)
        }}
      />
      {
        (type === 'album') &&
          <DelayedOnChangeComponent component={
            <Input
              icon='image outline'
              iconPosition='left'
              placeholder='Album'
              defaultValue={album}
              onChange={event => setAlbum(event.target.value)}
            />
          }/>
      }
    </div>
  )
}
