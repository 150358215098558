//https://blog.microideation.com/2021/11/29/automate-oauth-refresh-token-flow-using-axios-interceptors-in-reactjs-react-native-or-javascript/
//https://blog.liplex.de/axios-interceptor-to-refresh-jwt-token-after-expiration/
//https://gist.github.com/paulsturgess/ebfae1d1ac1779f18487d3dee80d1258

import axios from 'axios';
import { ImportApiCache } from "./cache";
import {IMPORT_API_URL,IMPORT_API_KEY,DEBUG} from "../Constants";

// Define defaults
const importAPI = axios.create({
  baseURL: IMPORT_API_URL,
  withCredentials:false,
  headers: {
    'Content-Type':     'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
});

async function refreshAccessToken(){

  //Clear token in localstorage
  ImportApiCache.clear();

  return axios.request({
    method: 'post',
    url: IMPORT_API_URL + '/v2/auth/token/',
    data: {
      api_key:  IMPORT_API_KEY
    }
  })
  .then(resp => resp.data.token)
  .then(function(token){
    DEBUG && console.info("...Import API token refreshed");
    //store token
    ImportApiCache.storeToken(token);
    return token;
  })
  .catch(function(error){
    console.error('...import API token error',error);
  });
}

//intercept request before being sent
importAPI.interceptors.request.use(
  function(config){

    const token = ImportApiCache.getToken();

    /*
    Access to XMLHttpRequest at '.../v2/playlist/import/?url=http:%2F%2Fwww.last.fm%2Fuser%2Fgrosbouff%2Floved' from origin 'https://spiff-radio.org' has been blocked by CORS policy: Response to preflight request doesn't pass access control check: The value of the 'Access-Control-Allow-Origin' header in the response must not be the wildcard '*' when the request's credentials mode is 'include'. The credentials mode of requests initiated by the XMLHttpRequest is controlled by the withCredentials attribute.
    */
    //TOUFIX URGENT CORS POLICY !! LINE SHOULD BE REMOVED.
    config.withCredentials = false;

    if(config.withCredentials && !token){
      throw new axios.Cancel('Missing app token.');
    }

    //handle auth token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      //reset auth if any
      delete config.headers.Authorization
    }
    return config;
  },
  function(error){
    return Promise.reject(error);
  }
)

//intercept response
importAPI.interceptors.response.use(
  function(response) {
    return response;
},async function(error) {

  const config = error.config;
  const token = ImportApiCache.getToken();
  const needToken = ( config.withCredentials && !token);

  if ( error.response ){ // Request made and server responded

    if (
      (
        needToken ||
        //401 (authentification error)
        //498 (token expired)
        [401,498].includes(error.response.status)
      )
      &&
      //avoid infinite retries
      !config._retry
    ) {

      DEBUG && console.info("Try to get a new Import API token...");

      //Set the retry flag to true
      config._retry = true;

      const access_token = await refreshAccessToken();

      //retry query
      DEBUG && console.info("Retry import API request",config);
      return importAPI(config);

    }

    //Handle API Error message
    if (error.response.data.message){
      console.info("Import API error message",error.response.data.message);
    }

  }


  //Not matched, return error
  console.error("Import API error",error);
  return Promise.reject(error);

});

export {importAPI};
