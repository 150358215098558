import React from "react"
import { Link } from "react-router-dom"
import { Button } from 'semantic-ui-react'

const HomePage = () => {
  return (
    <div id="homepage">
      <h3>Welcome to Spiff Radio !</h3>
      <div>
        <p>
        Spiff Radio is a collection of some of the best radio stations and playlists around the web.
        It’s a great way to discover some new tunes.
        </p>
        <p>
        Start your journey by listening our <Link to="/music/staff/tracks"><Button>Picks Playlist</Button></Link>; check our <Link to="/music/staff/playlists/live/favorited"><Button>Favorite Radios</Button></Link>, or load (almost) any playlist URL with the <Link to="/wizard"><Button>Search / Import</Button></Link> tool !
        </p>
        <br/>
        <p>
        PS : Wanna give some feedback ?  Please post <a href="https://github.com/gordielachance/spiff-radio-issues" target="_blank" rel="noreferrer">here</a> !
        </p>
      </div>
    </div>
  );
};

export default HomePage;
