import React from "react";
import {DEBUG} from "../Constants";
import {importAPI} from "./connect";

class LastFmAPI  extends React.Component {

    static async scrobbleTrack(track,sessionKey){

      if (!sessionKey){
  			throw 'Missing Last.fm user session key';
  		}

      try{
        track.isScrobblable();
      } catch (error) {
  			Promise.reject("cannot scrobble track: " + error);
  		}

      //build timestamp
      const duration_seconds = Math.floor(track.duration / 1000 );
      const now_seconds = Math.floor(Date.now() / 1000); //unix timestamp
      const timestamp = now_seconds - duration_seconds;//TOUFIX TOUCHECK what if we pause the track ? Maybe the started_playing should be set somewhere on player events

      const config = {
       method: 'post',
       url: '/v2/lastfm/track/scrobble/',
       withCredentials:true,
       data: {
         track: {...track},
         session_key:sessionKey,
         timestamp:timestamp
       }
      }

      DEBUG && console.log("Last.Fm scrobble track",config);

      return importAPI.request(config)
    }

    static async toggleFavoriteTrack(track,bool,sessionKey){

      if (bool === undefined){
        throw("Missing 'bool' parameter.");
      }

      if (!sessionKey){
  			throw 'Missing Last.fm user session key';
  		}

      try{
        track.isScrobblable();
      } catch (error) {
        if (bool){
          Promise.reject("cannot favorite track on Last.Fm: " + error);
        }else{
          Promise.reject("cannot unfavorite track on Last.Fm: " + error);
        }

  		}

      const config = {
       method: 'post',
       url: bool ? '/v2/lastfm/track/love/' : '/v2/lastfm/track/unlove/',
       withCredentials:true,
       data: {
         track: {...track},
         session_key:sessionKey
       }
      }

      DEBUG && console.log("Last.Fm toggle favorite track",config);

      return importAPI.request(config)
    }

    static async getSessionKey(token){
      const config = {
        method: 'get',
        url: '/v2/lastfm/user/session',
        params: {
          token:  token
        }
      }
      return importAPI.request(config)
      .then(resp => resp.data)
    }

    static async getUserInfo(sessionKey){
      const config = {
        method: 'get',
        url: '/v2/lastfm/user/info',
        params: {
          session_key:sessionKey
        }
      }
      return importAPI.request(config)
      .then(resp => resp.data.profile)
    }
}

export default LastFmAPI;
