/*index.jsx*/
import React from "react";
import PostCover from "./PostCover";
import {PostListItemMetas,PostMetaRadio} from "../PostMetas";

const PostListItemTile = (props) => {

  const playlist = props.playlist;
  const playlistTitle = playlist.title ? playlist.title : '(no title)';
  const playlistContent = playlist.annotation;
  const playlistUrl = playlist.getPostUrl();

  return (
    <>
      {
        playlist.isLive() &&
        <div className="post-metas post-metas-top post-metas-highligh">
          <PostMetaRadio playlist={props.playlist}/>
        </div>
      }
      <a className="playlist-figure" href={playlistUrl} onClick={props.onClick}>
        <PostCover
        image={playlist?.image}
        selected={props.selected}
        />
      </a>
      <div className="playlist-content">
        <h3 className="playlist-title handwritten" title={playlistTitle}>
          <a href={playlistUrl} onClick={props.onClick}>{playlistTitle}</a>
        </h3>
        <div
        className="playlist-excerpt"
        dangerouslySetInnerHTML={{
          __html: playlistContent
        }}>
        </div>
      </div>
      <div className="post-metas post-metas-bottom">
        <PostListItemMetas playlist={props.playlist}/>
      </div>
    </>
  )
}

export default PostListItemTile;
