import React from "react";
import { Button,Icon } from 'semantic-ui-react';
import classNames from "classnames";

const RadioPagination = (props) => {

  const paginationMeta = props.playlist?.getMetaValue('import_api/pagination');

  const getButton = () => {
    if (paginationMeta?.next){
      return(
        <Button
        icon
        labelPosition='right'
        primary
        onClick={props.onNext}
        >
        <Icon name='ellipsis horizontal' />
        Load more tracks
        </Button>
      )
    }else{
      return(
        <Button
        icon
        labelPosition='right'
        primary
        onClick={props.onReload}
        >
        <Icon name='refresh' />
        Reload
        </Button>
      );
    }
  }

  const button = getButton();

  return(
    <>
    {
      button &&
      <div className={classNames({
        radioPagination:true,
        freeze:(props.disabled)
      })}>
      {button}
      </div>
    }
    </>
  )


}

export default RadioPagination;
