import "./Tracklist.scss";
import React, { useState,useRef,useEffect,useContext } from "react";
import {DEBUG} from "../Constants";
import {isEqualTracklistComponent} from "../utils/EqualComponents";
import Track from "../track/Track";
import { Button,Input,Menu,Message } from 'semantic-ui-react';

import DelayedOnChangeComponent from "../components/DelayedOnChangeComponent";

import { PlayerContext } from "../player/Player.Context";
import TrackEditModal from "../track/edit/TrackEditModal";
import TrackQueueModal from "../track/TrackQueueModal";

const EmptyTracklist = (props) => {
  const noTrackMessage = props.canAddTracks ? <p>Why not add some ?</p> : undefined;
  return (
    <Message>
      <Message.Header>No tracks found.</Message.Header>
      {noTrackMessage}
    </Message>
  )
}

const Tracklist = (props) => {
  const [displayTracks, setDisplayTracks] = useState(props.tracks);
  const [filteredTracks, setFilteredTracks] = useState(props.tracks);

  const [playerState, playerDispatch] = useContext(PlayerContext);

  const playlisterPlaylist = props.playlisterPlaylist;

  const [filter, setFilter] = useState(undefined);

  const [editTrackIndex,setEditTrackIndex] = useState(undefined);
  const [queueTrackIndex,setQueueTrackIndex] = useState(undefined);

  const [addTrackModal, setAddTrackModal] = useState(false);

  const playlisterTrackIndex = playlisterPlaylist?.getCurrentTrack()?.index;

  const liRefs = useRef();//references for the track <li/> items

  //check for initial update
  const firstUpdate = useRef(true);

  const handleFilterTracks = e => {
    setFilter(e.target.value.trim().toUpperCase());
  }

  const handlePreviousEditModal = () => {
    setEditTrackIndex(editTrackIndex-1);
  }

  const handleNextEditModal = () => {
    setEditTrackIndex(editTrackIndex+1);
  }

  const handleCloseAddModal = () => {
    setAddTrackModal(false);
  }

  const handleCloseEditModal = () => {
    setEditTrackIndex(undefined);
  }

  const handleCloseQueueModal = () => {
    setQueueTrackIndex(undefined);
  }

  //filter
  useEffect(() => {

    let filtered;

    const doFilterTracks = (items,filter) => {
      if(filter){
        items = items.filter(function(jspfTrack){
          const artist = jspfTrack.creator.trim().toUpperCase();
          const title = jspfTrack.title.trim().toUpperCase();
          return ( artist.includes(filter) || title.includes(filter) );
        });
      }
      return items;
    }

    if(filter){
      filtered = doFilterTracks(props.tracks,filter);
      setFilteredTracks(filtered);
    }else{
      setFilteredTracks();
    }

  }, [props.tracks,filter]);

  //which tracks should we display ?
  useEffect(() => {
    const tracksToDisplay = (filteredTracks !== undefined) ? filteredTracks : props.tracks;
    setDisplayTracks(tracksToDisplay);
  },[props.tracks,filteredTracks]);

  //update LIs refs
  useEffect(() => {
    liRefs.current = (displayTracks || []).reduce((acc, track, index) => {
      acc[index] = React.createRef();
      return acc;
    }, {});

  },[displayTracks])

  useEffect(() => {

    const showTracks = filteredTracks ? filteredTracks : props.tracks;
    setDisplayTracks(showTracks);

    if (firstUpdate.current) return;
    props.onFilterTracks(filteredTracks);
  },[filteredTracks]);

  //scroll to LI item
  useEffect(()=>{
    if (props.scrollTo === undefined) return;
    const trackIndex = props.scrollTo;
    liRefs.current[trackIndex].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  },[props.scrollTo])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  })

  const handleTrackUpdate = async(index,jspf)=>{
    return props.onUpdateTrack(index,jspf);
  }

  const handleEditTrack = jspf => {
    handleTrackUpdate(editTrackIndex,jspf)
    .then(function(){
      handleCloseEditModal();
    })
  }

  const handleAddTrack = jspf => {
    props.onAddTrack(jspf)
    handleCloseEditModal();
  }

  return (
    <div className="tracklist">
      <meta itemProp="numTracks" content={displayTracks.length} />
      {
        //Modal is within tracklist rather than in each tracks because what if we add a new track ? + less components to load
        (editTrackIndex !== undefined) &&
        <TrackEditModal
        open={true}
        key={editTrackIndex}
        trackCount={props.tracks.length}
        track={props.tracks[editTrackIndex]}
        onPrevious={handlePreviousEditModal}
        onNext={handleNextEditModal}
        onClose={handleCloseEditModal}
        onSubmit={handleEditTrack}
        />
      }
      {
        addTrackModal &&
        <TrackEditModal
        open={true}
        newItem={true}
        trackCount={props.tracks.length}
        onClose={handleCloseAddModal}
        onSubmit={handleAddTrack}
        />
      }
      {
        (queueTrackIndex !== undefined) &&
        <TrackQueueModal
        index={queueTrackIndex}
        track={displayTracks[queueTrackIndex]}
        open={true}
        onClose={handleCloseQueueModal}
        />
      }
      <Menu secondary>
        {props.canAddTracks &&
          <Button
          onClick={(e)=>{setAddTrackModal(true)}}
          >Add Track</Button>
        }
        <Menu.Menu position='right'>
          <Menu.Item>
            <DelayedOnChangeComponent component={
              <Input
              icon='search'
              placeholder='Filter tracks...'
              onChange={handleFilterTracks}
              />
            }/>
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <div className="tracks">
      {
        displayTracks.length ?
            <ul>
            {

              displayTracks.map((track,key) => {
                var liRef =         liRefs.current ? liRefs.current[key] : undefined;
                var isCurrent =     ( playlisterTrackIndex === key);
                var progress =      isCurrent ? playerState.media_progress : 0;

                var playlisterTrack = props.playlisterPlaylist?.tracks.find(function(track) {
                  return (track.index === key);
                });

                var sources = playlisterTrack?.sources

                var playable =      playlisterTrack?.playable;
                var loading =       isCurrent ? props.loading : false;
                var playing =       isCurrent ? props.playing : false;

                return (
                  <li
                  key={"li-"+key}
                  ref={liRef}
                  >
                    <Track
                    /*
                    CAREFUL; check also the 'isEqualTrack' function used in React.useMemo if something changes here.
                    */
                    key={"track-"+key}
                    canEdit={props.canEditTracks}
                    canDelete={props.canAddTracks}
                    canQueue={props.canQueueTracks}
                    canFavorite={props.canFavoriteTracks}
                    index={key}
                    track={track}
                    sources={sources}
                    current={isCurrent}
                    loading={loading}
                    playable={playable}
                    playing={playing}
                    progress={progress}
                    onSelect={props.onSetTrack}
                    onTogglePlay={props.onTogglePlay}
                    onUpdate={handleTrackUpdate}
                    onDelete={props.onDeleteTrack}
                    onTrackEditModal={(i)=>{setEditTrackIndex(i)}}
                    onTrackQueueModal={(i)=>{setQueueTrackIndex(i)}}
                    />
                  </li>
                )
              })
            }
            </ul>
        :

        props.emptyTracklist ?
          props.emptyTracklist :
          <EmptyTracklist
          canAdd={props.canAddTracks}
          />
        }
      </div>


    </div>
  );

};

//export default React.memo(Tracklist,isEqualTracklistComponent);
export default Tracklist;
