/*index.jsx*/
import React,{useState,useEffect} from "react";
import { Input,Menu,Icon,Button } from 'semantic-ui-react';
import DelayedOnChangeComponent from "../../components/DelayedOnChangeComponent";

const PostListFilters = (props) => {

  const [excludedTags,setExcludedTags] = useState([]);

  const handleLayout = layout => {
    if (typeof props.onLayout === 'function') {
      props.onLayout( layout );
    }
  }

  const handleSearchFilter = e => {
    if (typeof props.onSearchFilter === 'function') {
      props.onSearchFilter( e.target.value.trim() );
    }
  }

  const toggleTag = (tag) => {
    console.log("EXCLUDE TAG",tag);

    const index = excludedTags.indexOf(tag);
    const newTags = [...excludedTags];


    if (index === -1) {
        newTags.push(tag);
    } else {
        newTags.splice(index, 1);
    }

    setExcludedTags(newTags);

  }

  useEffect(()=>{
    console.log("EXCLUDED TAGS",excludedTags);
  },[excludedTags])


  const getPostTagList = posts => {

    const getPostTagMetas = posts => {
      //get tags for each post
      return (posts || []).map(function(playlist){
        return playlist.getMetaValue('post_tag');
      })
    }

    //get metas for each post
    const metas = getPostTagMetas(posts);

    //flatten tags
    const flat = metas.flat(1);

    //object
    const pairsObj = flat.reduce(function (acc, curr) {
      return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
    }, {});

    //obj->array
    let sortedArr = [];
    for (var item in pairsObj) {
        sortedArr.push([item, pairsObj[item]]);
    }

    //sort array
    sortedArr.sort(function(a, b) {
        return b[1] - a[1];
    });

    return sortedArr;

  }

  const tagList = getPostTagList(props.items);

  return (
    <>
      <div id="postlist-filters">
        <Menu secondary>
        {
          (typeof props.onNewItem === 'function') &&
          <Menu.Menu position='left'>
            <Menu.Item>
              <Button
              onClick={props.onNewItem}
              >Add New</Button>
            </Menu.Item>
          </Menu.Menu>
        }
          <Menu.Menu position='right'>
            <Menu.Item
              name='grid-layout'
              active={props.layout === 'grid'}
              onClick={(e) => handleLayout('grid')}
            >
              <Icon name='block layout' />
            </Menu.Item>
            <Menu.Item
              name='list-layout'
              active={props.layout === 'list'}
              onClick={(e) => handleLayout('list')}
            >
              <Icon name='list layout' />
            </Menu.Item>
            <Menu.Item>
              <DelayedOnChangeComponent component={
                <Input
                icon='search'
                placeholder='Filter playlists...'
                onChange={handleSearchFilter}
                />
              }/>
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        {
          /*
          TOUFIX WIP
          tagList &&
          <div id='postlist-filter-tags'>
            <ul>
            {
              tagList.map((item,i) => {
                const tag = item[0];
                const count = item[1];
                const isExcluded = (excludedTags.indexOf(tag) !== -1);
                return (
                  <li key={i}>
                  <Label
                  className={!isExcluded ? 'active' : ''}
                  onClick={e=>toggleTag(tag)}
                  >
                    {tag}
                    <Label.Detail>{count}</Label.Detail>
                    <Icon name={!isExcluded ? 'delete' : 'plus'}/>
                  </Label>
                  </li>
                )
              })
            }
            </ul>
          </div>
          */
        }

      </div>
    </>
  )
}

export default PostListFilters;
