/*index.jsx*/
import React,{useState} from "react";
import {DEBUG,PLAYLIST_POST_TYPE,RADIO_POST_TYPE,LIVE_TAG_ID} from "../Constants";
import { Input,Menu,Icon,Button,Segment,Label } from 'semantic-ui-react';
import {useLocation,useNavigate,Link} from "react-router-dom";
import {getRouteUsername,getRoutePlaylistType,isRouteFavoritePosts} from "../utils/Utils";

const PostQueryFilters = (props) => {
  let navigate = useNavigate();
  let location = useLocation();

  const getTypeFromQuery = () => {
    if (props.query?.tag__in===LIVE_TAG_ID){
      return 'live';
    }
    switch(props.query?.post_type){
      case PLAYLIST_POST_TYPE:
        return 'static'
      break;
      case RADIO_POST_TYPE:
        return 'cloud'
      break;
    }
  }

  const username = getRouteUsername(location);
  const usernameCap = username.charAt(0).toUpperCase() + username.slice(1);
  const isFavorites = (props.query?.favorite_posts === true);
  let favoritesText = `${usernameCap}'s favorites`;
  favoritesText = !isFavorites ? 'Show ' + favoritesText : favoritesText;

  const playlistType = getTypeFromQuery();

  const playlistTypeOption = playlistType ? playlistType : 'all';

  const handleTypeFilter = (e,data) => {

    const oldType = playlistType ? 'playlists/' + playlistType : 'playlists';
    const newType = (data.value === 'all') ? 'playlists' : 'playlists/' + data.value;
    let newLocation = location.pathname.replace(oldType,newType);
    navigate(newLocation);
  }

  const getTypeUrl = type => {
    const oldType = playlistType ? 'playlists/' + playlistType : 'playlists';
    const newType = !type ? 'playlists' : 'playlists/' +type;
    return location.pathname.replace(oldType,newType);
  }

  const getFavoritedPostsUrl = (location,bool) => {
    const playlistType = getRoutePlaylistType(location);
    let oldStr = playlistType ? '/playlists/' + playlistType : '/playlists';
    let newStr = bool ? oldStr + '/favorited' : oldStr;

    let newPath = location.pathname.replace('/favorited','');
    return newPath.replace(oldStr,newStr);
  }



  return (
    <>
      <Menu secondary id="query-filters">
        <Menu.Menu position='right'>

          <Menu.Item>
            <Button id="query-filter-type" className="query-filter" as='div' labelPosition='right'>
              <Button icon><Icon name='rss' /> Type</Button>
              <Label
              basic
              pointing='left'
              className={!playlistType ? 'active' : ''}
              >
                <Link to={getTypeUrl()}>All</Link>
              </Label>
              <Label
              basic
              className={(playlistType === 'static') ? 'active' : ''}
              >
                <Link to={getTypeUrl('static')} title="Playlists stored in our database.">Static</Link>
              </Label>
              <Label
              basic
              className={(playlistType === 'cloud') ? 'active' : ''}
              >
                <Link to={getTypeUrl('cloud')} title="Playlists stored remotely.">Cloud</Link>
              </Label>
              <Label
              basic
              className={(playlistType === 'live') ? 'active' : ''}
              >
                <Link to={getTypeUrl('live')} title="Playlists stored remotely and constantly updated (radios).">Live</Link>
              </Label>
            </Button>
          </Menu.Item>

          {
            (username !== 'staff') &&
            <Menu.Item>
              <Link to={getFavoritedPostsUrl(location,!isFavorites)}>
                <Button
                icon
                id="query-filter-favorites"
                className="query-filter"
                as='div'
                >
                <Icon name={isFavorites ? 'heart' : 'heart outline' }/> {favoritesText}
                {
                  isFavorites &&
                  <Icon name='delete'/>
                }
                </Button>
              </Link>
            </Menu.Item>
          }
        </Menu.Menu>
      </Menu>
      {
        DEBUG &&
        <xmp className="debug">{JSON.stringify(props.query)}</xmp>
      }
    </>
  )
}

export default PostQueryFilters;
