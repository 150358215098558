import React from "react";
import {DEBUG} from "../Constants";
import {databaseAPI,DATABASE_API_GET_TOKEN_ENDPOINT,DATABASE_API_VALIDATE_TOKEN_ENDPOINT} from "./connect";
import playlistModel from  "../playlist/Playlist.Model";

export default class DatabaseAPI extends React.Component {

  static async getUserToken(username,password){
    const config = {
     method: 'post',
     url: DATABASE_API_GET_TOKEN_ENDPOINT,
     data: {
       username:username,
       password:password
     }
    }

    return databaseAPI.request(config)
    .then(resp => resp.data)
    .then(resp => resp.token)
  }

  static async validateUserToken(){
    const config = {
     method: 'post',
     url: DATABASE_API_VALIDATE_TOKEN_ENDPOINT,
     withCredentials:true
    }
    return databaseAPI.request(config);
  }

  static async getTags(){
    const config = {
     method: 'get',
     url: `/soundsystem/v2/tags`,
    }
    return databaseAPI.request(config)
    .then(resp => resp.data)
  }

  static async getPostByID(post_id) {

    if (!post_id){
      throw("Missing 'post_id' parameter.");
    }

    const config = {
     method: 'get',
     url: `/soundsystem/v2/playlist/${post_id}`,
    }

    return databaseAPI.request(config)
    .then(resp => resp.data)
    .then(function(item){
      return new playlistModel(item.playlist);
    })
  }

  static async getPosts(query_args,include_tracks){
    const config = {
     method: 'get',
     url: '/soundsystem/v2/playlists/',
     params: {
       query:query_args,
       include_tracks:include_tracks ?? false
     }
    }

    return databaseAPI.request(config)
    .then(resp => resp.data)
    .then(function(items){
      return items.map(a => new playlistModel(a.playlist));
    })
  }

  static async getMyProfile(){
  	const config = {
  	 method: 'get',
  	 url: '/soundsystem/v2/user/profile',
     withCredentials:true
  	}
  	return databaseAPI.request(config)
  	.then(resp => resp.data)
  }

  static async getUserProfile(username){
  	const config = {
  	 method: 'get',
     url: `/soundsystem/v2/user/${username}/profile`
  	}
  	return databaseAPI.request(config)
  	.then(resp => resp.data)
  }

  static async updateMyProfile(payload){
  	const config = {
  		method:	'post',
  		url: 		'/soundsystem/v2/user/profile',
      withCredentials:true,
  		data:	payload,
  		headers:{
  			'Content-Type': 'application/json'
      }
  	}
  	return databaseAPI.request(config)
  	.then(resp => resp.data)
    .then(function(data){
			DEBUG && console.log("USER DATA UPDATED",data);
			return data;
		})
  }

  static async addPost(playlist){

    if (!playlist){
      throw("Missing 'playlist' parameter.");
    }

    //wrap in a 'playlist' property, as in JSPF.
    const jspf = {
      playlist:{...playlist}
    }

    const config = {
     method: 'post',
     url: '/soundsystem/v2/playlist/new',
     withCredentials:true,
     data:{
       playlist:jspf
     },
     headers: {
         'Content-Type': 'application/json'
     }
    }
    return databaseAPI.request(config)
    .then(resp => resp.data)
    .then(resp => new playlistModel(resp.playlist))
  }

  static async updatePost(playlist){

    const post_id = playlist?.getMetaValue('post_id');

    if (!post_id){
      throw("Missing 'post_id' parameter.");
    }

    //wrap in a 'playlist' property, as in JSPF.
    const jspf = {
      playlist:playlist.toReducedPost()
    }

    const config = {
     method: 'post',
     url: '/soundsystem/v2/playlist/'+post_id+'/update',
     withCredentials:true,
     data:{
       playlist:jspf
     }
    }

    return databaseAPI.request(config)
    .then(resp => resp.data)
    .then(resp => new playlistModel(resp.playlist))
  }

  static async deletePost(playlist){
    playlist.setMetaValue('post_status','trash');
    return this.updatePost(playlist);
  }

  static async toggleFavoriteTracks(tracks,bool){

    if (bool === undefined){
      throw("Missing 'bool' parameter.");
    }

    if (!tracks){
      throw("Missing 'tracks' parameter.");
    }

    //to JSPF
    tracks = tracks.map(function(track){
      return {...track}
    })

    const config = {
      method:	'post',
      url: 		bool ? '/soundsystem/v2/tracks/favorite' : '/soundsystem/v2/tracks/unfavorite',
      withCredentials:true,
      data:{
        tracks:tracks
      },
      headers: {
          'Content-Type': 'application/json'
      }
    }

    DEBUG && console.log("TOGGLE FAVORITE TRACKS",tracks,config);

    return databaseAPI.request(config)
    .then(resp => resp.data)
    .then(resp => new playlistModel(resp.playlist))
  }

  static async toggleFavoriteTrack(track,bool){
    if (!track){
      throw("Missing 'track' parameter.");
    }
    return this.toggleFavoriteTracks([track],bool);
  }

  static async toggleFavoritePost(playlist,bool){

    if (bool === undefined){
      throw("Missing 'bool' parameter.");
    }

    if (!playlist){
      throw("Missing 'playlist' parameter.");
    }

    const post_id =  playlist.getMetaValue('post_id');
    if (!post_id) throw new Error("Missing post ID.");

    const config = {
      method: 'post',
      url: 		bool ? '/soundsystem/v2/playlist/'+post_id+'/favorite' : '/soundsystem/v2/playlist/'+post_id+'/unfavorite',
      withCredentials:true
    }

    DEBUG && console.log("TOGGLE FAVORITE POST",post_id,config);

    return databaseAPI.request(config)
    .then(resp => resp.data)
  }

  static async toggleQueueTracks(tracks,playlist,bool){

    const post_id = playlist?.getMetaValue('post_id');

    if (!post_id){
      throw("Missing 'post_id' parameter.");
    }

    if (!tracks){
      throw("Missing 'tracks' parameter.");
    }

    if (bool === undefined){
      throw("Missing 'bool' parameter.");
    }

    //to JSPF
    tracks = tracks.map(function(track){
      return {...track}
    })

    const config = {
      method:	'post',
      url: 		bool ? `/soundsystem/v2/playlist/${post_id}/queue` : `/soundsystem/v2/playlist/${post_id}/dequeue`,
      withCredentials:true,
      data:{
        tracks:tracks
      },
      headers: {
          'Content-Type': 'application/json'
      }
    }

    DEBUG && console.log("TOGGLE QUEUE TRACKS",config,tracks);

    return databaseAPI.request(config)
    .then(resp => resp.data)
    .then(resp => new playlistModel(resp.playlist))
  }

  static async toggleFavoriteUser(username,bool){
    if (bool === undefined){
      throw("Missing 'bool' parameter.");
    }
    if (!username){
      throw("Missing 'username' parameter.");
    }
    const config = {
      method:	'post',
      url: 		bool ? `/soundsystem/v2/user/${username}/favorite` : `/soundsystem/v2/user/${username}/unfavorite`,
      withCredentials:true,
      headers: {
          'Content-Type': 'application/json'
      }
    }

    DEBUG && console.log("TOGGLE FAVORITE USER",config,username);

    return databaseAPI.request(config)
    .then(resp => resp.data)
  }

  static async toggleQueueTrack(track,playlist,bool){
    if (!track){
      throw("Missing 'track' parameter.");
    }
    return this.toggleQueueTracks([track],playlist,bool);
  }
}
