
import React,{useState} from "react";
import classNames from "classnames";
import { Button } from 'semantic-ui-react';
import { TrackSource } from "./TrackSource";
import { TrackLink } from "./TrackLink";
import "./TrackUrls.scss";
import Pluralize from 'react-pluralize';

export const TrackUrls = (props) => {

  const trackLinksCount = props.links?.length || 0;
  const [showLinks, setShowLinks] = useState(false);

  return (
    <div
    className={classNames({
      'track-row':                true,
      'track-urls-container':  true,
      active:                     props.open
    })}
    >
    {
      props.sources &&
      <ul className='track-sources-container'>
      {
        (props.sources || []).map((source,key) => {

          var isCurrent =     source.current;
          var playing =       isCurrent ? props.playing : false;
          var loading =       isCurrent ? props.loading : false;
          var progress =      isCurrent ? props.progress : 0;

          return (<TrackSource
            key={key}
            index={source.index}
            current={isCurrent}
            playing={playing}
            loading={loading}
            progress={progress}
            url={source.url}
            playlisterSource={source}
            onTogglePlay={props.onTogglePlay}
            onSelect={props.onSourceSelect}
            />)
        })
      }
      </ul>
    }
    {
      (trackLinksCount!==0) &&
        <div
        className={classNames({
          'track-links-container':  true,
          active: showLinks
        })}
        >
          <p className='track-links-handle'>
            <Button
            onClick={(e)=>{setShowLinks(true)}}
            >
              <Pluralize singular={'more link'} count={trackLinksCount} />
            </Button>
          </p>
          <ul>
          {
            (props.links || []).map((url,key) => {

              return (<TrackLink
                key={key}
                url={url}
                />)
            })
          }
          </ul>
        </div>
    }
    </div>
  );
}
