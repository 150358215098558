import React from "react";
import { Menu } from 'semantic-ui-react';
import {useNavigate,useLocation,matchRoutes,Link} from "react-router-dom";
import { useUser } from "../user/User.Context";

const UserMenu = (props) => {

  let navigate = useNavigate();
  let location = useLocation();
  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;


  const userImage = props.profile?.image ? 'url('+props.profile?.image+')' : '';

  const getLocationParams = () => {
    const routesMatch = matchRoutes([
      {path:'/user/:username'},
      {path:'/user/:username/:page'},
      {path:'/user/:username/:page/:subpage'},
    ], location);

    const match = routesMatch ? routesMatch[0] : undefined;
    return match?.params;
  }

  const locationParams = getLocationParams();
  const isMe = (locationParams?.username === myUsername);

  const userProfileLink = locationParams?.username ? `/user/${locationParams?.username}` : '#';
  const userFavoritedTracksLink = locationParams?.username ? `/user/${locationParams?.username}/tracks` : '#';
  const userPlaylistsLink = locationParams?.username ? `/user/${locationParams?.username}/playlists` : '#';
  const userSettingsLink = locationParams?.username ? `/user/${locationParams?.username}/settings` : '#';

  return (
    <div id="user-menu">
      <Menu vertical id="left-menu">
        <Menu.Item>
          <span className="menu-title handwritten">{locationParams?.username}</span>
          <Menu.Menu>
            <Link to={userProfileLink}>
            <Menu.Item
              name='Profile'
              active={(locationParams?.page===undefined)}
            />
            </Link>
            <Link to={userFavoritedTracksLink} disabled={!userContext.isLogged}>
              <Menu.Item
                name='Tracks'
                active={( (locationParams?.page==='tracks') && (locationParams?.subpage==='favorited') )}
              />
            </Link>
            <Link to={userPlaylistsLink} disabled={!userContext.isLogged}>
              <Menu.Item
                name='Playlists'
                active={( (locationParams?.page==='playlists') && (locationParams?.subpage===undefined) )}
              />
            </Link>
            {
              isMe &&
              <Link to={userSettingsLink}>
                <Menu.Item
                  name='Settings'
                  active={(locationParams?.page==='settings')}
                />
              </Link>
            }
          </Menu.Menu>
        </Menu.Item>
      </Menu>
    </div>
  )
}

export default UserMenu;
