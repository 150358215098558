import React,{useState,useEffect} from "react";
import {DEBUG,RADIO_POST_TYPE} from "../Constants";
import { useNavigate,useLocation } from "react-router-dom";
import { useApp } from '../App.Context';
import playlistModel from  "../playlist/Playlist.Model";
import {isEqualLiveSettings,getWizardPlaylistUrl} from "../utils/Utils";
import {isEqualWizardBoxComponent} from "../utils/EqualComponents";
import { WizardTabUrl } from "./Tabs/Url";
import { WizardTabArtist } from "./Tabs/Artist";
import { WizardTabLastFm } from "./Tabs/LastFm";
import "./Wizard.scss";
import WizardSubmitButton from "./SubmitButton";
import { Tab } from 'semantic-ui-react';

const ImporterThumbnail = props => {
  const importerImage = props.importer?.infos.image  ? 'url('+props.importer.infos.image+')' : '';
  const importerName = props.importer?.infos.name ?? '';

  return(
    <div className="importer-image-container">
      <div
      className="importer-image cover-img"
      style={{backgroundImage: importerImage}}
      title={importerName}
      ></div>
    </div>
  )
}

const WizardBox = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const appContext = useApp();
  const [playlist,setPlaylist] = useState(props.playlist);
  const [importer,setImporter] = useState();

  //prop updated
  useEffect(() => {
    setPlaylist(props.playlist);
  }, [props.playlist]);

  useEffect(() => {

    if (!appContext.importers) return;

    const searchUrl = playlist?.getMetaValue('import_url');
    if (!searchUrl){
      setImporter();
      return;
    }

    DEBUG && console.log("Search importer for URL...",searchUrl);

    const newImporter = appContext.getImporterByUrl(searchUrl);

    DEBUG && console.log("...importer found",newImporter);

    setImporter(newImporter);

  }, [appContext.importers,playlist]);

  const handleChange = newPlaylist => {

    newPlaylist = newPlaylist || new playlistModel();
    newPlaylist.setMetaValue('post_type',RADIO_POST_TYPE);

    const changed = !isEqualLiveSettings(playlist,newPlaylist);

    if (changed){
      setPlaylist(newPlaylist);
    }
  }

  //redirect on wizard submit
  const handleSubmit = e => {

    e.preventDefault();

    const newPath = getWizardPlaylistUrl(playlist);

    if ( !newPath || (newPath === location.pathname) ) return;
    navigate(newPath);
  }

  const getPanes = () => {

    const submitBt = <div id="wizard-submit">
      <ImporterThumbnail importer={importer}/>
      <WizardSubmitButton disabled={!importer || props.loading} loading={props.loading}/>
      </div>

    return [
      {
        menuItem: 'search',
        render: () => <Tab.Pane>
          <WizardTabArtist
          playlist={playlist}
          onChange={handleChange}
          />
          {submitBt}
      </Tab.Pane> },
      {
        menuItem: 'url',
        render: () => <Tab.Pane>
          <WizardTabUrl
          playlist={playlist}
          onChange={handleChange}
          />
          {submitBt}
      </Tab.Pane> },
      {
        menuItem: 'lastfm',
        render: () => <Tab.Pane>
          <WizardTabLastFm
          playlist={playlist}
          onChange={handleChange}
          />
          {submitBt}
      </Tab.Pane> }
    ]
  }

  return (
    <form
    id="simple-importer"
    onSubmit={handleSubmit}
    >
      <Tab panes={getPanes()} defaultActiveIndex={1}/>
    </form>
  )
}

//export default React.memo(WizardBox,isEqualWizardBoxComponent);
export default WizardBox;
