import {DEBUG} from "../Constants";

export class ImportApiCache {

  static TOKEN_KEY = 'import_token';

  static storeToken(token: string): void {
    DEBUG && console.info("Store import API token",token);
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  static clear(): void {
    DEBUG && console.info("Clear import API token");
    localStorage.removeItem(this.TOKEN_KEY);
  }

  static getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }
}
